import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

const LoginValidation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const accessToken = searchParams.get("access_token");
  const refreshToken = searchParams.get("refresh_token");
  const from = searchParams.get("from");
  if (accessToken) {
    localStorage.setItem("alambraAccessToken", accessToken);
  }
  if (refreshToken) {
    localStorage.setItem("alambraRefreshToken", refreshToken);
  }

  const isValidAccessToken = async () => {
    const storedAccessToken = localStorage.getItem("alambraAccessToken");
    if (storedAccessToken) {
      try {
        const response = await axios.get(`/api/tokens/validate-access-token`, {
          headers: {
            Authorization: `access-token ${storedAccessToken}`,
          },
        });

        if (response.status === 200) {
          return true;
        } else {
          return false;
        }
      } catch (error) {
        // Handle error response from the backend
        console.log(error);
        return false;
      }
    }
    return false;
  };

  const isValidRefreshToken = async () => {
    const storedRefreshToken = localStorage.getItem("alambraRefreshToken");
    if (storedRefreshToken) {
      try {
        const response = await axios.get(
          `$/api/tokens/validate-refresh-token`,
          {
            headers: {
              Authorization: `refresh-token ${storedRefreshToken}`,
            },
          }
        );

        if (response.status === 200) {
          return true;
        } else {
          return false;
        }
      } catch (error) {
        // Handle error response from the backend
        console.log(error);
        return false;
      }
    }
    return false;
  };

  useEffect(() => {
    const refreshAccessToken = async () => {
      if (!(await isValidRefreshToken())) {
        navigate("/login");
      }

      try {
        const response = await axios.get(`/api/tokens/refresh-access-token`, {
          headers: {
            Authorization: `refresh-access-token ${localStorage.getItem(
              "alambraRefreshToken"
            )}`,
          },
        });

        if (response.status === 200) {
          const { accessToken } = response.data;
          localStorage.setItem("alambraAccessToken", accessToken);
          if (!(await isValidAccessToken())) {
            navigate("/login");
          } else {
            const localCart = JSON.parse(localStorage.getItem("alambraCart"));
          if (localCart) {
            axios.post("/api/cart/localToCart",{products:localCart},{headers: {
              Authorization: `access-token ${localStorage.getItem("alambraAccessToken")}`,
              "refresh-token": localStorage.getItem("alambraRefreshToken"),
            }}).then((res)=>{
              if(res.data.success){
                localStorage.removeItem("alambraCart");
              }
            }).catch((err)=>{
              console.log(err);
            })
          }
            if (from) {
              navigate(from);
            } else {
              navigate("/");
            }
          }
        } else {
          // Handle error response from the backend
          console.log(response.data);
        }
      } catch (error) {
        // Handle network or other errors
        console.log(error);
      }
    };

    const checkTokens = async () => {
      if (!(await isValidAccessToken())) {
        if (!(await isValidRefreshToken())) {
          navigate("/login");
        } else {
          refreshAccessToken();
        }
      } else {
        const localCart = JSON.parse(localStorage.getItem("alambraCart"));
          if (localCart) {
            axios.post("/api/cart/localToCart",{products:localCart},{headers: {
              Authorization: `access-token ${localStorage.getItem("alambraAccessToken")}`,
              "refresh-token": localStorage.getItem("alambraRefreshToken"),
            }}).then((res)=>{
              if(res.data.success){
                localStorage.removeItem("alambraCart");
              }
            }).catch((err)=>{
              console.log(err);
            })
          }
        if (from) {
          navigate(from);
        } else {
          navigate("/");
        }
      }
    };

    checkTokens();
  }, [accessToken, refreshToken, navigate, from]);

  return null;
};

export default LoginValidation;
