import React, {useEffect } from "react";
import { useNavigate } from "react-router-dom";
import '../Styles/NotFound/NotFound.css'

const NotFound = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  },[])
  return <div >
     <div className="notFoundContainer">
     <h3>Sorry, we couldn't find the page</h3>
    <button onClick={() => navigate("/")}>Go to home page</button>
     </div>
   

    
  </div>;
};

export default NotFound;
