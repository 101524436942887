import React, { useEffect, useState } from "react";
import { AdvancedImage, lazyload } from "@cloudinary/react";
import { cloudinary } from "../Utils/CloudinarySetup";
import { BeatLoader } from "react-spinners";
import "../Styles/About/About.css";

const About = () => {
  const [imagesLoaded, setImagesLoaded] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      setImagesLoaded(true);
    }, 1000); // 1000 milliseconds = 1 second
  }, []);

  return (
    <>
      {imagesLoaded ? (
        <div className="about">
          <AdvancedImage
            cldImg={cloudinary
              .image("alambra stock images/sqsflwh1owdf3hvs73io")
              .format("auto")
              .quality("auto")}
            plugins={[lazyload()]}
          />
          <AdvancedImage
            cldImg={cloudinary
              .image("alambra stock images/zon9irv00zscavdacgz5")
              .format("auto")
              .quality("auto")}
            plugins={[lazyload()]}
          />
          <AdvancedImage
            cldImg={cloudinary
              .image("alambra stock images/mdyfnysmvv4a31racerg")
              .format("auto")
              .quality("auto")}
            plugins={[lazyload()]}
          />
          <AdvancedImage
            cldImg={cloudinary
              .image("alambra stock images/prartilb3ywkkmgurymj")
              .format("auto")
              .quality("auto")}
            plugins={[lazyload()]}
          />
          <AdvancedImage
            cldImg={cloudinary
              .image("alambra stock images/wqixpk4e3rqxmfzm3sks")
              .format("auto")
              .quality("auto")}
            plugins={[lazyload()]}
          />
          <AdvancedImage
            cldImg={cloudinary
              .image("alambra stock images/b5avdy5yycnyenqw4p3l")
              .format("auto")
              .quality("auto")}
            plugins={[lazyload()]}
          />
        </div>
      ) : (
        <div className="loadingIndicator">
          <h5>Loading</h5>
          <BeatLoader color="#3498db" size={"1.5vw"} />
        </div>
      )}
    </>
  );
};

export default About;
