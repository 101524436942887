import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

const CustomersCard = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState('Today');
  const [customersData, setCustomersData] = useState();
  // eslint-disable-next-line
  const [error, setError] = useState();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleFilterClick = (newFilter) => {
    setFilter(newFilter);
    setIsOpen(false);
  };



  useEffect(() => {
    const getCustomersData = () => {
      axios.get(`/api/admin/dashboardData/customerCount/${filter}`, {
        headers: {
          Authorization: `admin-access-token ${localStorage.getItem("alambraAdminAccessToken")}`
        }
      })
        .then((res) => {
          setCustomersData(res.data);
        })
        .catch((err) => {
          setError(err.response.data.message);
        })
    };
    getCustomersData();
  }, [filter]);

  return (
    <div className="col-xxl-4 col-xl-12">
      <div className="card info-card customers-card">
        <div className="filter">
          <p style={{ cursor: 'pointer' }} className="icon"  data-bs-toggle="dropdown" onClick={toggleDropdown}><i className="bi bi-three-dots"></i></p>
          <ul className={`dropdown-menu dropdown-menu-end dropdown-menu-arrow ${isOpen ? 'show' : ''}`}>
            <li className="dropdown-header text-start">
              <h6>Filter</h6>
            </li>
            <li style={{ cursor: 'pointer' }}><p className="dropdown-item" onClick={() => handleFilterClick('Today')} >Today</p></li>
            <li style={{ cursor: 'pointer' }}><p className="dropdown-item" onClick={() => handleFilterClick('This Month')}>This Month</p></li>
            <li style={{ cursor: 'pointer' }}><p className="dropdown-item" onClick={() => handleFilterClick('This Year')}>This Year</p></li>
          </ul>
        </div>
        <div className="card-body">
          <h5 className="card-title">Customers <span>| {filter}</span></h5>
          <div className="d-flex align-items-center">
            <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
              <i className="bi bi-people"></i>
            </div>
            <div className="ps-3">
              <h6>{customersData?.TotalCustomers}</h6>
              <span className={`${customersData?.CustomersProgress > 0 ? 'text-success' : 'text-danger'} small pt-1 fw-bold`}>{customersData?.CustomersProgress.toFixed(2)}%</span>
              <span className="text-muted small pt-2 ps-1">{customersData?.CustomersProgress > 0 ? 'increase' : 'decrease'}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomersCard;
