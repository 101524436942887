import React from 'react'
import { Formik, Field, ErrorMessage } from "formik";
import loginValidationSchema from "../Schemas/loginValidationSchema";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import useAdminStore from '../Store/adminStore';
import "../Styles/Login/Login.css";
import { toast } from "react-toastify";
import {
  AdvancedImage,
  lazyload,
  placeholder,
} from "@cloudinary/react";
import { cloudinary } from "../Utils/CloudinarySetup";

const AdminLogin = () => {
  const {getLoggedInAdmin} = useAdminStore();
  const navigate = useNavigate();
  const initialValues = {
    email: "",
    password: "",
  };
  const handleSubmit = (values) => {
    axios.post(`/api/admin/auth/login`, values).then((res) => {
      if (res.data.success) {
        localStorage.setItem("alambraAdminAccessToken",res.headers.get("access-token"));
        localStorage.setItem("alambraAdminRefreshToken",res.headers.get("refresh-token"));
        toast.success(res.data.message);
        getLoggedInAdmin();
        navigate("/admin/dashboard");
      } 
    }).catch((err) => {
      toast.error(err.response.data.message);
      })
  };

  

  
  return (
    <div className="login">
      <div className="mobile-screen">
        <div className="background-container">
          <div className="banner-section">
            <AdvancedImage cldImg={cloudinary.image("alambra stock images/rdbqy0fyqgfg0t16qo0g").format("auto").quality("auto")}
            plugins={[lazyload(), placeholder({ mode: "blur" })]} />
          </div>
        </div>

        <div className="login-section">
          <div className="login-headerText">
            <h5>ADMIN LOGIN</h5>
          </div>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={loginValidationSchema}
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div className="login-form-container">
                  <Field placeholder="Email" type="email" name="email"  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error"
                  />
                  <Field
                    placeholder="Password"
                    type="password"
                    name="password"
                    
                  />
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="error"
                  />
                  <Link to="/admin/forgot-password">forgot password ?</Link>
                  <button type="submit">Login</button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export default AdminLogin

