import React, { useRef, useState, useEffect, useContext} from "react";
import "../Styles/OTPInput/OTPInput.css";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import axios from "axios";
import { FPContext } from "../Context/FPContext";

const OTPInput = ({ length = 6 }) => {
  const inputRefs = useRef([]);
  const [timeLeft, setTimeLeft] = useState(10);
  const [isDisabled, setIsDisabled] = useState(true);
  const [timeoutDuration, setTimeoutDuration] = useState(30);
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {OTPSentRes,OTPVerifiedRes,setOTPVerifiedRes,setOTPVerified,setOTPSentRes ,email} = useContext(FPContext)
  
  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
      return () => clearInterval(timer); // Cleanup timer on component unmount
    } else {
      setIsDisabled(false);
    }
  }, [timeLeft]);


  const handleInputChange = (e, index) => {
    const value = e.target.value;
    if (value.length === 1) {
      if (index < length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
    if (value.length > 1) {
      const values = value.slice(0, length).split("");
      values.forEach((val, i) => {
        inputRefs.current[i].value = val;
      });
      inputRefs.current[Math.min(values.length, length - 1)].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      if (inputRefs.current[index].value === "") {
        if (index > 0) {
          inputRefs.current[index - 1].focus();
        }
      }
    }
  };

  const handlePaste = (e) => {
    const pasteData = e.clipboardData.getData("text");
    if (pasteData.length === length && !isNaN(pasteData)) {
      pasteData.split("").forEach((char, index) => {
        inputRefs.current[index].value = char;
      });
    }
  };

  const handleResendClick = async () => {
    setIsDisabled(true);
    setOTPSentRes("");
    setError("");
    setTimeLeft(timeoutDuration * 2);
    setTimeoutDuration(timeoutDuration * 2);
    try {
      const response = await axios.post(`/api/auth/forgot-password/${email}`);
      if (response.data.success) {
      setOTPSentRes(response.data.message);
      }
    } catch (error) {
      setError(error.response.data.message);
    }
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      setOTPSentRes("");
      const otp = inputRefs.current.map((input) => input.value).join("");
      // const isValid = otp.length === length && !isNaN(otp);
      const response = await axios.post("/api/auth/verify-otp", {email,otp});
      setIsSubmitting(false);
      if (response?.data?.success) {
        setOTPVerified(true);
        setOTPVerifiedRes(response?.data?.message);
      }
    } catch (error) {
      setIsSubmitting(false);
      inputRefs.current.forEach((input) => {
        if (input) input.value = "";
      });
      setError(error?.response?.data?.message);
      console.log("error:",error)
    }
  };

  const formatTime = () => {
    if (timeLeft >= 3600) {
      const hours = Math.floor(timeLeft / 3600);
      const minutes = Math.floor((timeLeft % 3600) / 60);
      return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
        2,
        "0"
      )} hours`;
    } else if (timeLeft >= 60) {
      const minutes = Math.floor(timeLeft / 60);
      const seconds = timeLeft % 60;
      return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
        2,
        "0"
      )} minutes`;
    } else {
      return `${timeLeft}s`;
    }
  };

  function maskEmail(Email) {
    const [localPart, domain] = Email.split("@");
    const maskedLocalPart =
      "*".repeat(localPart.length - 2) + localPart.slice(-2);
    return `${maskedLocalPart}@${domain}`;
  }

  return (
    <div className="otp-container" onPaste={handlePaste}>
      {!OTPVerifiedRes && !error && OTPSentRes && (
        <div className="fp-success">
          {OTPSentRes} to <span>{maskEmail(email)}</span>
          <CheckCircleIcon className="fp-success-icon" />
        </div>
      )}
      <div className="otp-box-container">
        {Array(length)
          .fill(0)
          .map((_, index) => (
            <input
              key={index}
              type="text"
              maxLength="1"
              ref={(el) => (inputRefs.current[index] = el)}
              onChange={(e) => handleInputChange(e, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              className="otp-input"
            />
          ))}
      </div>

      <button disabled={isSubmitting} onClick={handleSubmit} className="otp-submit">
        {isSubmitting ? "Submitting..." : "Submit"}
      </button>
      {error && <p className="otp-error">{error}</p>}
      <div className="otp-resend-section">
        <p>Didn't Recieve Code?</p>
        <h5
          onClick={!isDisabled ? handleResendClick : null}
          disabled={isDisabled}
          className={`otp-resend-button ${isDisabled ? "disabled" : ""}`}
        >
          {isDisabled ? (
            <>
              <span> Resend OTP </span>in <span>{formatTime()}</span>
            </>
          ) : (
            "Resend OTP"
          )}
        </h5>
      </div>
    </div>
  );
};

export default OTPInput;
