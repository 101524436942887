import React, { useState, useRef, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useIntl } from "react-intl";
import { FaBars } from "react-icons/fa";
import { RxCaretDown } from "react-icons/rx";
import { IoClose } from "react-icons/io5";
import useProductStore from "../Store/productStore";
import useUtilsStore from "../Store/utilsStore";
import { cloudinary } from "../Utils/CloudinarySetup";
import {
  AdvancedImage,
  lazyload,
} from "@cloudinary/react";
import "../Styles/AppBar/AppBar.css";
import axios from "axios";

const Navbar = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const { products, getProducts } = useProductStore();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [showShopDropDown, setShowShopDropDown] = useState(false);
  const [showUserDropdown, setShowUserDropdown] = useState(false);
  const {
    loggedInUser,
    getLoggedInUser,
    loggedInUserLoading,
    userCartCount,
    getUserCartCount,
    localCartCount,
    getLocalCartCount,
  } = useUtilsStore();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [banner, setBanner] = useState();

  const Server = process.env.REACT_APP_SERVER;
  const searchRef = useRef(null);
  const timeoutRef = useRef(null);
  const userTimeoutRef = useRef(null);
  const ShopDropDownData = [
    "Perfumes",
    "Body Mist",
    "Hair Mist",
    "Bakhoor",
    "Premium Oils",
    "Faraash",
  ];

  useEffect(() => {
    axios
      .get("/api/admin/banner/bannerImages")
      .then((res) => {
        setBanner(res.data.bannerImages?.[0].mobile_banner);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("alambraAccessToken");
    localStorage.removeItem("alambraRefreshToken");
    getLoggedInUser();
    getUserCartCount();
    navigate("/");
  };

  const handleMyOrders = () => {
    setIsMobileMenuOpen(false);
    navigate("/orders");
  };

  const handleSearch = async (value) => {
    if (value === "" || value === " ") {
      setSearchResults([]);
      return;
    }
    let results;
    const fetchResults = async () =>
      await products?.filter((product) => {
        return product.name_en.toLowerCase().includes(value.toLowerCase());
      });
    if (products) {
      results = await fetchResults();
    } else {
      await getProducts();
      results = await fetchResults();
    }
    setSearchResults(results);
  };

  const handleMenuToggle = () => {
    setShowShopDropDown(false);
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleCart = () => {
    setIsMobileMenuOpen(false);
    navigate("/cart");
  };

  const handleSearchClear = () => {
    setSearchQuery("");
    setSearchResults([]);
  };

  const handleNavigate = (id) => {
    handleSearchClear();
    setIsMobileMenuOpen(false);
    navigate(`/product/${id}`);
  };

  const handleClickOutside = (event) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setSearchQuery("");
      setSearchResults([]);
    }
  };
  const handleShopHover = () => {
    clearTimeout(timeoutRef.current);
    setShowShopDropDown(true);
  };

  const handleShopLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setShowShopDropDown(false);
    }, 200);
  };
  const handleDropdownHover = () => {
    clearTimeout(timeoutRef.current);
  };

  const handleUserHover = () => {
    clearTimeout(userTimeoutRef.current);
    setShowUserDropdown(true);
  };

  const handleUserLeave = () => {
    userTimeoutRef.current = setTimeout(() => {
      setShowUserDropdown(false);
    }, 200);
  };

  const handleUserDropdownHover = () => {
    clearTimeout(userTimeoutRef.current);
  };

  const handleLinkClick = () => {
    setShowShopDropDown(false);
    setIsMobileMenuOpen(false);
  };

  const menuList = () => {
    return (
      <ul className="menu-list" onMouseLeave={handleShopLeave}>
        <li>
          <Link to="/" onClick={handleLinkClick}>
            {intl.formatMessage({ id: "nav.home" })}
          </Link>
          <RxCaretDown className="arrow" />
        </li>
        <li
          className="shoplist"
          onMouseEnter={handleShopHover}
          onClick={() => setShowShopDropDown(!showShopDropDown)}
        >
          {intl.formatMessage({ id: "nav.shop" })}

          <RxCaretDown className="arrow" />
        </li>

        <li>
          <Link to="/blog" onClick={handleLinkClick}>
            {intl.formatMessage({ id: "nav.blog" })}
          </Link>
          <RxCaretDown className="arrow" />
        </li>
        <li>
          <Link to="/about" onClick={handleLinkClick}>
            {intl.formatMessage({ id: "nav.about" })}
          </Link>
          <RxCaretDown className="arrow" />
        </li>
      </ul>
    );
  };

  const shopListCategory = () => {
    return (
      <div
        className="shoplist-category"
        onMouseEnter={handleDropdownHover}
        onMouseLeave={handleShopLeave}
      >
        <ul>
          {ShopDropDownData.map((item, index) => (
            <li key={index}>
              <Link to={`/shop/${item}`} onClick={handleLinkClick}>
                <div className="shoplist-category-item">{item} </div>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    getLoggedInUser();
  }, [getLoggedInUser]);

  useEffect(() => {
    !loggedInUserLoading && loggedInUser
      ? getUserCartCount()
      : getLocalCartCount();
  }, [loggedInUser, loggedInUserLoading, getUserCartCount, getLocalCartCount]);

  return (
    <div id="navbar" className="navbar_main">
      <div className="main_menu">
        <div className="menu-icons">
          <div className="left-menu">
            <div
              className="mobile-menu-icon navbar-icons"
              onClick={handleMenuToggle}
            >
              <FaBars />
            </div>

            <div className="search" ref={searchRef}>
              <div className="search_icon navbar-icons">
                <img src="../../assets/images/search_icon.png" alt="" />
              </div>

              <input
                className="search-input"
                type="text"
                name="search"
                id="searchText"
                value={searchQuery}
                placeholder="Search..."
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                  handleSearch(e.target.value);
                }}
              />
              {/* Display search results */}
              {searchResults?.length > 0 ? (
                <div className="search-results">
                  {searchResults.map((result, index) => (
                    <div
                      onClick={() => handleNavigate(result._id)}
                      className="search-result"
                      key={index}
                    >
                      <img src={`${Server}${result?.rawImages[0]?.path}`} alt="" />
                      <span>{`${result.name_en} ${result.category_en} ${result.quantity}`}</span>
                    </div>
                  ))}
                </div>
              ) : (
                searchResults?.length === 0 &&
                searchQuery !== "" && (
                  <div className="no-search-results">
                    <span>No results found!</span>
                  </div>
                )
              )}
            </div>
          </div>
          <div className="center-menu">
            <div className="menu-logo-container">
              <AdvancedImage
                onClick={() => {
                  setIsMobileMenuOpen(false);
                  navigate("/");
                }}
                className="logo"
                cldImg={cloudinary.image(
                  "alambra stock images/tjv2kbqk4g9ztwutvbic"
                )}
                plugins={[]}
                alt=""
              />
            </div>
            {menuList()}
          </div>
          <div className="right-menu">
            <div className="cart_icon navbar-icons" onClick={handleCart}>
              <img src="../../assets/images/cart_icon.png" alt="" />
              {userCartCount ? (
                <span className="cartCount">{userCartCount}</span>
              ) : (
                localCartCount && (
                  <span className="cartCount">{localCartCount}</span>
                )
              )}
            </div>
            <div
              className="user_box"
              onMouseEnter={handleUserHover}
              onMouseLeave={handleUserLeave}
            >
              <div className="user_icon navbar-icons">
                <img src="../../assets/images/user.png" alt="" />

                {/* {loggedInUser && (
                  <p className="user-name">
                    {loggedInUser?.username || loggedInUser?.name_google}
                  </p>
                )} */}
              </div>
              {showUserDropdown && loggedInUser && (
                <div
                  className="account-options"
                  onMouseEnter={handleUserDropdownHover}
                >
                  {loggedInUser ? (
                    <>
                      {" "}
                      <button className="my-orders" onClick={handleMyOrders}>
                        My Orders
                      </button>
                      <button className="logout" onClick={handleLogout}>
                        logout
                      </button>
                    </>
                  ) : // <>
                  //   <button onClick={() => navigate("/login")}>Login</button>
                  // </>
                  null}
                </div>
              )}
            </div>
          </div>
        </div>
        {showShopDropDown && shopListCategory()}
      </div>

      <div className={`sideBar ${isMobileMenuOpen ? "open" : ""}`}>
        <IoClose className="close" onClick={handleMenuToggle} />
        <div
          style={{
            backgroundImage: `url('${Server}${banner?.url}')`,
            backgroundSize: "cover",
          }}
          className="sidebarContent"
        >
          <div className="sidebar-logo">
            <AdvancedImage
              onClick={() => {
                setIsMobileMenuOpen(false);
                navigate("/");
              }}
              className="logo"
              cldImg={cloudinary.image(
                "alambra stock images/qqcvhdxhttpyacpud7dj"
              )}
              plugins={[lazyload()]}
              alt="logo"
            />
          </div>
          {menuList()}
          {showShopDropDown && shopListCategory()}
          <div className="account-info">
            {loggedInUser && (
              <div className="username-mobile">
                Welcome,&nbsp;
                <span>
                  {loggedInUser?.username || loggedInUser?.name_google}{" "}
                </span>
              </div>
            )}
            <div className="account-mobile">
              {loggedInUser ? (
                <>
                  {" "}
                  <button className="orders-mobile" onClick={handleMyOrders}>
                    orders
                  </button>
                  <button className="logout-mobile" onClick={handleLogout}>
                    logout
                  </button>
                </>
              ) : (
                <>
                  <button
                    className="login-mobile"
                    onClick={() => {
                      setIsMobileMenuOpen(false);
                      navigate("/login");
                    }}
                  >
                    Login
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
