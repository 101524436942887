import React, { useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import enMessages from "../../Utils/Translations/en.json";
import arMessages from "../../Utils/Translations/ar.json";
import useUtilsStore from "../../Store/utilsStore";
import Navbar from "../Appbar";
import "../../Styles/localizationProvider.css";

const LocalizationProvider = ({ children }) => {
  const [locale, setLocale] = useState("en");
  const { isRtl, setRtl } = useUtilsStore();

  useEffect(() => {
    setRtl(locale === "ar");
  }, [locale, setRtl]);

  const handleLocaleChange = (e) => {
    setLocale(e.target.value);
  };

  const messages = {
    en: enMessages,
    ar: arMessages,
  };

  // const isRtl = locale === 'ar';
  const containerClassName = isRtl ? "rtl-container" : "";
  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      <div className={containerClassName}>
        <div id="appbarwithlang" className="appbar">
          <div className="languageSwitch">
            <button value={"en"} onClick={handleLocaleChange}>
              English
            </button>
            <button value={"ar"} onClick={handleLocaleChange}>
              عربي
            </button>
          </div>
          <Navbar />
        </div>

        {children}
      </div>
    </IntlProvider>
  );
};

export default LocalizationProvider;
