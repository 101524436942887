import { create } from "zustand";
import axios from "axios";



const useProductStore = create((set) => ({
  products: null,
  productsLoading: false,
  productsError: null,
  getProducts: async () => {
    set({ productsLoading: true });
    try {
      const res = await axios.get(`/api/products`);
      set({ products: res.data.products, productsLoading: false });
    } catch (error) {
      set({ productsError: error, productsLoading: false });
    }
  },

  homeProducts: null,
  homeProductsLoading: false,
  homeProductsError: null,
  getHomeProducts: async () => {
    set({ homeProductsLoading: true });
    try {
      const res = await axios.get(`/api/products/home-products`);
      set({ homeProducts: res.data.homeProducts, homeProductsLoading: false });
    } catch (error) {
      set({ homeProductsError: error, homeProductsLoading: false });
    }
  },

  productById: null,
  productByIdLoading: false,
  productByIdError: null,
  getProductById: async (id) => {
    set({ productByIdLoading: true });
    try {
      const res = await axios.get(`/api/products/${id}`);
      set({ productById: res.data.product, productByIdLoading: false });
    } catch (error) {
      set({ productByIdError: error, productByIdLoading: false });
    }
  },

  userCart: null,
  userCartLoading: false,
  userCartError: null,

  getUserCart: async() => {
    set({ userCartLoading: true });
    try {
      const res = await axios.get(`/api/cart/userCart`, {
        headers: {
          Authorization: `access-token ${localStorage.getItem("alambraAccessToken")}`,
        },
      });
      set({ userCart: res.data.userCart, userCartLoading: false });
    }
    catch (error) {
      set({userCart:null, userCartError: error, userCartLoading: false });
    } 
  },

  userOrders: null,
  userOrdersLoading: false,
  userOrdersError: null,
  getUserOrders: async () => {
    set({ userOrdersLoading: true });
    try {
      const res = await axios.get(`/api/orders/user-orders`, {
        headers: {
          Authorization: `access-token ${localStorage.getItem("alambraAccessToken")}`,
        },
      });
      set({ userOrders: res.data.orders, userOrdersLoading: false });
    } catch (error) {
      set({ userOrdersError: error, userOrdersLoading: false });
    }
  },

  productByIdForReview: null,
  productByIdForReviewLoading: false,
  productByIdForReviewError: null,
  getProductByIdForReview: async (id) => {
    set({ productByIdForReviewLoading: true });
    try {
      const res = await axios.get(`/api/products/product-with-reviews/${id}`, {
        headers: {
          Authorization: `access-token ${localStorage.getItem("alambraAccessToken")}`,
        },
      });
      set({
        productByIdForReview: res.data.product,
        productByIdForReviewLoading: false,
      });
    } catch (error) {
      set({
        productByIdForReviewError: error,
        productByIdForReviewLoading: false,
      });
    }
  },

  relatedProducts: null,
  relatedProductsLoading: false,
  relatedProductsError: null,
  getRelatedProducts: async (id) => {
    set({ relatedProductsLoading: true });
    try {
      const res = await axios.get(`/api/products/related-products/${id}`);
      set({ relatedProducts: res.data, relatedProductsLoading: false });
    } catch (error) {
      set({ relatedProductsError: error, relatedProductsLoading: false });
    }
  },
}));

export default useProductStore;
