import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactECharts from 'echarts-for-react';


const SalesByCategory = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState('Today');
  const [salesByCategoryData, setSalesByCategoryData] = useState();
  // eslint-disable-next-line
  const [error, setError] = useState();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleFilterClick = (newFilter) => {
    setFilter(newFilter);
    setIsOpen(false);
  };



  useEffect(() => {
    const getSalesByCategoryData = () => {
      axios.get(`/api/admin/dashboardData/salesByCategory/${filter}`, {
        headers: {
          Authorization: `admin-access-token ${localStorage.getItem("alambraAdminAccessToken")}`
        }
      })
        .then((res) => {
          setSalesByCategoryData(res.data.salesByCategory);
        })
        .catch((err) => {
          setError(err.response.data.message);
        })
    };
    getSalesByCategoryData();
  }, [filter]);

  const option = {
    tooltip: {
      trigger: 'item'
    },
    legend: {
      top: '5%',
      left: 'center'
    },
    series: [{
      name: 'Items sold',
      type: 'pie',
      radius: ['40%', '70%'],
      avoidLabelOverlap: false,
      label: {
        show: false,
        position: 'center'
      },
      emphasis: {
        label: {
          show: true,
          fontSize: '18',
          fontWeight: 'bold'
        }
      },
      labelLine: {
        show: false
      },
      data: salesByCategoryData?.length > 0 && salesByCategoryData?.map(item => ({ value: item.totalSales, name: item.category }))
    }]
  };

  return (
    <div className="card">
      <div className="filter">
        <p className="icon" data-bs-toggle="dropdown" onClick={toggleDropdown}><i className="bi bi-three-dots"></i></p>
        <ul className={`dropdown-menu dropdown-menu-end dropdown-menu-arrow ${isOpen ? 'show' : ''}`}>
          <li className="dropdown-header text-start">
            <h6>Filter</h6>
          </li>
          <li>
            <p className="dropdown-item" onClick={() => handleFilterClick('Today')} style={{ margin: 0, cursor: 'pointer' }}>
              Today
            </p>
          </li>
          <li>
            <p className="dropdown-item" onClick={() => handleFilterClick('This Month')} style={{ margin: 0, cursor: 'pointer' }}>
              This Month
            </p>
          </li>
          <li>
            <p className="dropdown-item" onClick={() => handleFilterClick('This Year')} style={{ margin: 0, cursor: 'pointer' }}>
              This Year
            </p>
          </li>

        </ul>
      </div>
      <div className="card-body pb-0">
        <h5 className="card-title">Sales by Category <span>| {filter}</span></h5>
        <ReactECharts option={option} style={{ minHeight: '400px' }} />
      </div>
    </div>
  );
};

export default SalesByCategory;
