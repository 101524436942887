import * as Yup from "yup";

const AdminBannerValidationSchema = Yup.object().shape({
    imageFiles: Yup.array().of(
        Yup
          .mixed()
          .test('fileType', '* Invalid file type, only images are allowed', value => {
            if (!value) return true; // Allow empty values (no file uploaded)
            
            const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
            return allowedTypes.includes(value.type);
          })
          .test('fileSize', '* File size too large', value => {
            if (!value) return true; // Allow empty values (no file uploaded)
      
            const maxSize = 10 * 1024 * 1024;
            return value.size <= maxSize;
          })
      )
})

export default AdminBannerValidationSchema