import React from "react";
import "../Styles/RatingChart/RatingChart.css";

const RatingChart = ({ ratings }) => {
  const Total = ratings.reduce((total, rating) => total + rating.count, 0);
  return (
    <div className="rating-chart">
      {ratings.map((rating, index) => {
        const percentage = (rating.count / Total) * 100 || 0;
        return (
          <div className="bar-container" key={index}>
            <p className="label">{rating.stars}</p>
            <div
              className="bar"
              style={{
                background: `linear-gradient(to right, ${
                  index === 3 ? "orange" : index === 4 ? "red" : "#109d55"
                } ${percentage}%, #e6dddde0 ${percentage}%)`,
              }}
            ></div>
            <p className="count">
              {rating.count} ({percentage.toFixed(1)}%)
            </p>
          </div>
        );
      })}
    </div>
  );
};

export default RatingChart;
