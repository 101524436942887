import React, { useEffect } from "react";
import "../Styles/ShippingPolicy/ShippingPolicy.css";

const ShippingPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="shippingPolicy">
      <h4 className="shippingPolicy_title">Shipping Policy</h4>
      <p className="shippingPolicy_text">
        We know how important is to receive your order on time, so we have the
        Best Courier Partners to deliver your product to you as soon as we can.{" "}
      </p>
      <h5 className="shippingPolicy_subtitle">Standard Delivery:</h5>
      <p className="shippingPolicy_text">
        We have a standard delivery option in which, the product will be
        dispatched in 24-48 hours of the order.
      </p>
      <p className="shippingPolicy_text">
        Depends upon the location, the product will be delivered in the below
        mentioned time after your order has been dispatched.
      </p>
      <table>
        <thead>
          <tr>
            <th>Location</th>
            <th>Delivery Time</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>All over UAE</td>
            <td>1-7 working days</td>
          </tr>
        </tbody>
      </table>
      <h5 className="shippingPolicy_subtitle">
        Shipping Charges are as follows:{" "}
      </h5>
      <table>
        <thead>
          <tr>
            <th>Value of Order</th>
            <th>Shipping Charges</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Below AED 500</td>
            <td>AED 25</td>
          </tr>
          <tr>
            <td>AED 500 and Above</td>
            <td>Free</td>
          </tr>
        </tbody>
      </table>
      <p className="shippingPolicy_text">
        *If you don't receive your order in 7-10 Days, please email us at
        info@alambraperfumes.com with all the details or you may contact us at
        +971 56 669 5370 or (04) 885 1505 between 10.00 - 06.00 on all working
        days.
      </p>
    </div>
  );
};

export default ShippingPolicy;
