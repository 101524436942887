import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useProductStore from "../Store/productStore";
import useUtilsStore from "../Store/utilsStore";
import axios from "axios";
import { useIntl } from "react-intl";
import { cloudinary } from "../Utils/CloudinarySetup";
import { AdvancedImage } from "@cloudinary/react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../Styles/home.css";

export const Home = () => {
  const { homeProducts, getHomeProducts } = useProductStore();
  const { isRtl } = useUtilsStore();
  const intl = useIntl();
  const navigate = useNavigate();
  const [banner, setBanner] = useState();
  const [isReady, setIsReady] = useState(false);
  const Server = process.env.REACT_APP_SERVER;
  useEffect(() => {
    setIsReady(false);
    getHomeProducts();
    setIsReady(true);
  }, [getHomeProducts]);

  useEffect(() => {
    axios
      .get("/api/admin/banner/bannerImages")
      .then((res) => {
        setBanner(res.data.bannerImages);
        setIsReady(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const appbar = document.getElementById("appbarwithlang");
    appbar.style.position = "fixed";
    return () => {
      appbar.style.position = "sticky";
    };
  }, []);

  useEffect(() => {
    const icons = document.getElementsByClassName("navbar-icons");
    document.getElementById("navbar").style.backgroundColor = "#00000087";
    window.scrollTo(0, 0);
    for (let i = 0; i < icons.length; i++) {
      icons[i].style.color = "white";
      const imgElement = icons[i].querySelector("img");
      if (imgElement) {
        imgElement.style.filter = "none"; // Invert the image colors
      }
    }
    setIsReady(true);
    return () => {
      document.getElementById("navbar").style.backgroundColor = "whitesmoke";
      for (let i = 0; i < icons.length; i++) {
        icons[i].style.color = "black";
        const imgElement = icons[i].querySelector("img");
        if (imgElement) {
          imgElement.style.filter = "invert(100%)"; // Invert the image colors
        }
      }
    };
  }, []);

  useEffect(() => {
    const icons = document.getElementsByClassName("navbar-icons");
    const handleScroll = () => {
      const position = window.pageYOffset;
      const bannerContainer = document.getElementById("banner-container");
      const bannerContainerHeight = bannerContainer.offsetHeight;
      const navbar = document.getElementById("navbar");

      if (position < bannerContainerHeight / 1.75) {
        navbar.style.backgroundColor = "#00000087";
        for (let i = 0; i < icons.length; i++) {
          icons[i].style.color = "white";
          const imgElement = icons[i].querySelector("img");
          if (imgElement) {
            imgElement.style.filter = "none"; // Invert the image colors
          }
        }
      } else {
        navbar.style.backgroundColor = "whitesmoke";
        for (let i = 0; i < icons.length; i++) {
          icons[i].style.color = "black";
          const imgElement = icons[i].querySelector("img");
          if (imgElement) {
            imgElement.style.filter = "invert(100%)"; // Invert the image colors
          }
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      for (let i = 0; i < icons.length; i++) {
        icons[i].style.color = "black";
        const imgElement = icons[i].querySelector("img");
        if (imgElement) {
          imgElement.style.filter = "invert(100%)"; // Invert the image colors
        }
      }
    };
  }, []);

  const handleNavigate = (id) => {
    navigate(`/product/${id}`);
  };

  const handleBannerButtonClick = () => {
    const element = document.getElementById("collections");
    if (element) {
      const elementTop = element.getBoundingClientRect().top;
      const offsetPercentage = 0.15; // Desired offset as a percentage of the viewport height
      const offset = window.innerHeight * offsetPercentage;
      window.scrollTo({
        top: window.pageYOffset + elementTop - offset,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <div
        className="home"
        style={{ visibility: isReady ? "visible" : "hidden" }}
      >
        <div className="bannerWrapper">
          <div id="banner-container" className="bannerContainer">
            <Carousel
              showStatus={true} // Hide status indicators
              showThumbs={false} // Show thumbnail indicators
              emulateTouch={true} // Enable touch swipe
              infiniteLoop={true} // Infinite loop
              useKeyboardArrows={true} // Enable keyboard navigation
              autoPlay={true} // Autoplay the carousel
              interval={2000} // Autoplay interval (2 seconds)
              transitionTime={1000} // Transition time (1 second)
              showIndicators={true} // Show indicators
            >
              {banner &&
                banner?.[0]?.head_banner?.map((image, index) => (
                  <div key={index}>
                    <img
                      src={`${Server}${image?.url}`}
                      alt=""
                      className={"bannerImage" + (index + 1)}
                    />
                  </div>
                ))}
            </Carousel>

            <div className="buttonContainer">
              <button
                className="bannerButton"
                onClick={handleBannerButtonClick}
              >
                {intl.formatMessage({ id: "banner.buttonText" })}
              </button>
            </div>
          </div>
          <div className="offerContainer">
            <span className="offerTitle">
              {intl.formatMessage({ id: "offer.title" })}
            </span>
            <span className="offerText">
              {intl.formatMessage({ id: "offer.description1" })}
            </span>
            <span className="offerText">
              {intl.formatMessage({ id: "offer.description2" })}
            </span>
          </div>
        </div>
        <div className="splCollectionsSection">
          <div className="splCollectionContainer">
            <div className="logo2Container">
              <AdvancedImage
                className="logo2"
                cldImg={cloudinary
                  .image("alambra stock images/ojicfhmdw0lg3vibxaqs")
                  .format("auto")
                  .quality("auto")}
                alt=""
              />
            </div>
            <span className="splCollectionTitle">
              {intl.formatMessage({ id: "specialCollections.title" })}
            </span>
          </div>
          <div className="splCollectionCardContainer">
            {homeProducts?.specialCollections.map((item, index) => (
              <>
                <div
                  key={index}
                  className={`splCollectionCard ${item?.out_of_stock ? 'outOfStock' : ''}`}
                 
                  onClick={() => {
                    if (!item?.out_of_stock) {
                      handleNavigate(item._id);
                    }
                  }}
                >
                  {item?.out_of_stock && <div className="outOfStockOverlay">Out of Stock</div>}
                  <div className="splCollectionImageCard">
                    <img
                      className="splCollectionImage"
                      src={`${Server}${item?.rawImages[0]?.path}`}
                      alt=""
                    />
                  </div>
                  <span className="splCollectionItemTitle">
                    {isRtl ? item.name_ar : item.name_en.toUpperCase()}
                  </span>
                  <div className="splCollectionItemPriceContainer">
                    <span className="splCollectionItemPrice">
                      AED {item.price}
                    </span>
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
        <div className="stockImageContainer">
          {banner?.[0]?.banner1?.map((image, index) => (
            <img
              key={index}
              src={`${Server}${image?.url}`}
              alt=""
              className="sImage"
            />
          ))}
        </div>
        <div className="quoteContainer">
          <span className="quoteTitle">
            {intl.formatMessage({ id: "quote.title" })}
          </span>
          <span className="quoteText">
            {intl.formatMessage({ id: "quote.text" })}
          </span>
        </div>
        <p className="trendingTitle">
          {intl.formatMessage({ id: "trending.title" })}
        </p>
        <div className="whatsNewContainer">
          <div className="logo2Container">
            <AdvancedImage
              className="logo2"
              cldImg={cloudinary
                .image("alambra stock images/ojicfhmdw0lg3vibxaqs")
                .format("auto")
                .quality("auto")}
              alt=""
            />
          </div>
          <span className="whatsNewTitle">
            {intl.formatMessage({ id: "what's new.title" })}
          </span>
        </div>
        <div className="whatsNewCardContainer">
          {homeProducts?.newCollections.map((item, index) => (
            <div
              key={index}
              className={`whatsNewCard ${item?.out_of_stock ? 'outOfStock' : ''}`}
              onClick={() => {
                if (!item?.out_of_stock) {
                  handleNavigate(item._id);
                }
              }}
            >
              {item?.out_of_stock && <div className="outOfStockOverlay">Out of Stock</div>}
              <div className="whatsNewImageCard">
                <img
                  src={`${Server}${item?.rawImages[0]?.path}`}
                  alt=""
                  className="whatsNewImage"
                />
              </div>
              <span className="whatsNewItemTitle">
                {isRtl ? item.name_ar : item.name_en.toUpperCase()}
              </span>
              <div className="whatsNewItemPriceContainer">
                <span className="whatsNewItemPrice">AED {item.price}</span>
              </div>
            </div>
          ))}
        </div>
        <div className="stockImageContainer2">
          <img src={`${Server}${banner?.[0]?.banner2?.url}`} alt="" />
        </div>
        <div className="collectionContainer" id="collections">
          <div className="logo2Container">
            <img className="logo2" src="../../assets/images/LOGO3.png" alt="" />
          </div>
          <span className="collectionTitle">
            {intl.formatMessage({ id: "collections.title" })}
          </span>
        </div>
        <div className="collectionCardContainer">
          {homeProducts?.collections.map((item, index) => (
            <div
              key={index}
              className={`collectionCard ${item?.out_of_stock ? 'outOfStock' : ''}`}
              onClick={() => {
                if (!item?.out_of_stock) {
                  handleNavigate(item._id);
                }
              }}
            >
               {item?.out_of_stock && <div className="outOfStockOverlay">Out of Stock</div>}
              <div className="collectionImageCard">
                <img
                  src={`${Server}${item?.rawImages[0]?.path}`}
                  alt=""
                  className="collectionImage"
                />
              </div>
              <span className="collectionItemTitle">
                {isRtl ? item.name_ar : item.name_en.toUpperCase()}
              </span>
              <div className="collectionItemPriceContainer">
                <span className="collectionItemPrice">AED {item.price}</span>
              </div>
            </div>
          ))}
        </div>

        <div className="giftImgCont">
          <img
            src={`${Server}${banner?.[0]?.banner3?.url}`}
            alt=""
            className="giftImg"
          />
        </div>
      </div>
    </>
  );
};
