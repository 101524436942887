import { ObjectIdCreationDate } from "./DateFromObjectID";
import { getHighestRepeatingOrderStatus } from "./orderHistoryStatusFinder";
import { createItemObject } from "./ItemObjectMaker";
export const PreDataMaker = (
  source,
  price,
  formData,
  loggedInUser,
  userOrders,
  productById,
  userCart
) => {
  return {
    source,
    total: price?.total,
    tax: price?.tax,
    shipping_price: price?.shipping,
    customer: {
      name: formData.name,
      email: loggedInUser?.email,
      phone: formData?.phone,
      id: loggedInUser?._id,
      history: {
        registered_since:
          loggedInUser?.createdAt ?? ObjectIdCreationDate(loggedInUser?._id),
        loyalty_level: userOrders?.length ?? 0,
        is_email_verfied: loggedInUser?.isVerified
          ? loggedInUser?.isVerified
          : loggedInUser?.googleId
          ? true
          : loggedInUser?.facebookId
          ? true
          : false,
      },
      order_history:
        userOrders?.map((order) => ({
          purchased_at: order?.product?.[0]?.order_date,
          amount: order?.cancellation_metadata?.updated_total || order?.total,
          status: getHighestRepeatingOrderStatus(order),
          buyer: {
            phone: order?.product?.[0]?.phone,
            email: order?.product?.[0]?.email || loggedInUser?.email,
            name: order?.product?.[0]?.shipping_address?.name,
          },
          shipping_address: {
            city: order?.product?.[0]?.shipping_address?.city,
            address: order?.product?.[0]?.shipping_address?.addressLine1,
            zip: order?.product?.[0]?.shipping_address?.postalCode || "000000",
          },
        })) || [],
    },
    shipping: {
      address: formData.addressLine1,
      city: formData.city,
    },
    items:
      source === "buynow"
        ? [createItemObject(productById, 1)]
        : userCart?.product?.map((item, index) =>
            createItemObject(
              item?.productDetails,
              userCart.product?.[index]?.quantity
            )
          ) || [],
  };
};
