import React from "react";
import { useFormik } from "formik";
import loginValidationSchema from "../Schemas/loginValidationSchema";
import axios from "axios";
import "../Styles/AdminAddAdmin/AdminAddAdmin.css";
import Swal from "sweetalert2";

const AdminAddAdmin = () => {
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginValidationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);
        const response = await axios.post("/api/admin/auth/signup", values, {
          headers: {
            Authorization: `admin-access-token ${localStorage.getItem(
              "alambraAdminAccessToken"
            )}`,
          },
        });
        setSubmitting(false);
        if (response.data.success) {
          Swal.fire({
            icon: "success",
            title: response.data.message,
          }).then((result) => {
            result.isConfirmed && formik.resetForm();
          })
        }
      } catch (error) {
        setSubmitting(false);
        Swal.fire({
          icon: "error",
          title: error.response.data.message,
        }).then((result) => {
          result.isConfirmed && formik.resetForm();
        })
      }
    },
  });
  return (
    <div className="addAdmin">
      <h4 className="title">Add Admin</h4>
      <form onSubmit={formik.handleSubmit}>
        <div className="form-group">
          <div className="grouping-with-errorbox">
            <input
              name="email"
              placeholder="Email*"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              className="email"
              type="email"
            />
            {formik.touched.email && formik.errors.email && (
              <div className="error-msg">{formik.errors.email}</div>
            )}
          </div>
          <div className="grouping-with-errorbox">
            <input
              name="password"
              placeholder="Password*"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              className="password"
              type="password"
            />
            {formik.touched.password && formik.errors.password && (
              <div className="error-msg">{formik.errors.password}</div>
            )}
          </div>
          <button
            className="submit-button"
            disabled={formik.isSubmitting}
            type="submit"
          >
            {formik.isSubmitting ? "Processing..." : "Submit"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AdminAddAdmin;
