import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import "../Styles/PaymentSuccess/PaymentSuccess.css";

export const PaymentSuccess = () => {
  const [statusMessage, setStatusMessage] = useState(
    "Fetching payment status..."
  );
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const source = queryParams.get("source");
    const paymentId = queryParams.get("payment_id");

    if (source === "tabby") {
      if (paymentId) {
        // Handle Tabby specific logic
        checkPaymentStatus(paymentId);
      } else {
        // Payment ID is missing
        setStatusMessage("Payment ID is missing. Please verify Orders.");
      }
    } else if (source) {
      // Source is present but not 'tabby'
      setStatusMessage("Invalid payment source. Please check the URL.");
    } else {
      // Handle generic success message for other gateways
      setStatusMessage(
        "Your order is placed successfully & will be shipped soon."
      );
    }
  }, [location]);

  const checkPaymentStatus = async (paymentId) => {
    try {
      const response = await axios.get(
        `/api/tabby/retrieve-payment/${paymentId}`
      );
      const { status } = response.data;
      if (status === "AUTHORIZED") {
        setStatusMessage(
          "Your order is placed successfully & will be shipped soon."
        );

        // order processing here ...
        await axios.post(`/api/tabby-orders/create-order/${paymentId}`);
      } else {
        setStatusMessage("Investigation required. Please contact support.");
      }
    } catch (error) {
      setStatusMessage("Error retrieving payment status.");
      console.error("Error:", error);
    }
  };

  return (
    <div className="paymentSuccessContainer">
      <h3>Payment Success</h3>
      <p>{statusMessage}</p>
    </div>
  );
};
