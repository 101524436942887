import React, { useState, useRef } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { TagsInput } from "react-tag-input-component";
import AdminProductsValidationSchema from "../Schemas/AdminProductsSchema";
import { TiDelete } from "react-icons/ti";
import "../Styles/ProductControl/ProductControl.css";
import axios from "axios";
import Swal from "sweetalert2";

const AddProduct = () => {
  const [selectedImages, setSelectedImages] = useState([]);
  const navigate = useNavigate();
  const fileInputRef = useRef();
  const formik = useFormik({
    initialValues: {
      name_en: "",
      name_ar: "",
      description_en: "",
      description_ar: "",
      category_en: "",
      category_ar: "",
      qty: "",
      unit: "",
      price: "",
      ori_price: "",
      topNote: [],
      heartNote: [],
      baseNote: [],
      imageFiles: [],
      listed_in: "",
    },

    validationSchema: AdminProductsValidationSchema,
    onSubmit: (values, { setSubmitting }) => {
      values.category_ar =
        values.category_en === "perfume"
          ? "عطر"
          : values.category_en === "oil"
          ? "زيت"
          : values?.category_en === "Faraash"
          ? "فراش"
          : values.category_en === "Hair Mist"
          ? "بخاخ الشعر"
          : values.category_en === "Body Mist"
          ? "بخاخ الجسم"
          : values.category_en === "Bakhoor"
          ? "بخور"
          : "";
      const formData = new FormData();
      for (const key in values) {
        if (values.hasOwnProperty(key)) {
          const value = values[key];
          if (Array.isArray(value)) {
            // Append an array field
            for (const item of value) {
              formData.append(key, item);
            }
          } else {
            formData.append(key, value);
          }
        }
      }

      setSubmitting(true);
      axios
        .post(`/api/admin/products/create`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `admin-access-token ${localStorage.getItem(
              "alambraAdminAccessToken"
            )}`,
          },
        })
        .then((res) => {
          setSubmitting(false);
          if (res.data.success) {
            Swal.fire({
              icon: "success",
              title: res.data.message,
              showConfirmButton: false,
              timer: 1500,
            }).then(() => {
              navigate("/admin/all-products");
            });
          }
        })
        .catch((err) => {
          setSubmitting(false);
          Swal.fire({
            icon: "error",
            title: err.response.data.message,
          });
        });
    },
  });

  const handleImageChange = (event) => {
    const newImages = Array.from(event.currentTarget.files);
    setSelectedImages([...selectedImages, ...newImages]);
    formik.setFieldValue("imageFiles", [
      ...formik.values.imageFiles,
      ...newImages,
    ]);
  };

  const removeImage = (index) => {
    fileInputRef.current.value = "";
    const updatedImages = [...selectedImages];
    updatedImages.splice(index, 1);
    setSelectedImages(updatedImages);

    const updatedFormikImages = [...formik.values.imageFiles];
    updatedFormikImages.splice(index, 1);
    formik.setFieldValue("imageFiles", updatedFormikImages);
  };

  return (
    <>
      <h4 style={{ textAlign: "center", marginTop: "2vw" }}>Add Product</h4>
      <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
        <div className="product-control-container">
          <div className="grouping-with-errorbox">
            <input
              name="name_en"
              placeholder="Name in English*"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name_en}
              className="name-english"
              type="text"
            />
            {formik.touched.name_en && formik.errors.name_en && (
              <div className="error-msg">{formik.errors.name_en}</div>
            )}
          </div>
          <div className="grouping-with-errorbox">
            <input
              name="name_ar"
              placeholder="Name in Arabic*"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name_ar}
              className="name-arabic"
              type="text"
            />
            {formik.touched.name_ar && formik.errors.name_ar && (
              <div className="error-msg">{formik.errors.name_ar}</div>
            )}
          </div>
          <div className="grouping-with-errorbox">
            <textarea
              name="description_en"
              placeholder="Description in English"
              cols="21"
              rows="5"
              value={formik.values.description_en}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="description-english"
            />
            {formik.touched.description_en && formik.errors.description_en && (
              <div className="error-msg">{formik.errors.description_en}</div>
            )}
          </div>
          <div className="grouping-with-errorbox">
            <textarea
              name="description_ar"
              placeholder="Description in Arabic"
              cols="21"
              rows="5"
              value={formik.values.description_ar}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="description-english"
            />
            {formik.touched.description_ar && formik.errors.description_ar && (
              <div className="error-msg">{formik.errors.description_ar}</div>
            )}
          </div>
          <div className="grouping-with-errorbox">
            <select
              name="category_en"
              className="category-english"
              aria-label="Category"
              placeholder="Category*"
              onChange={formik.handleChange}
              value={formik.values.category_en}
              onBlur={formik.handleBlur}
            >
              <option value="">Select Category*</option>
              <option value="perfume">Perfume</option>
              <option value="oil">Premium oil</option>
              <option value="Faraash">Faraash</option>
              <option value="Hair Mist">Hair Mist</option>
              <option value="Body Mist">Body Mist</option>
              <option value="Bakhoor">Bakhoor</option>
            </select>
            {formik.touched.category_en && formik.errors.category_en && (
              <div className="error-msg">{formik.errors.category_en}</div>
            )}
          </div>
          <div className="division">
            <div className="grouping-with-errorbox">
              <input
                name="qty"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Quantity*"
                value={formik.values.qty}
                className="quantity"
                type="number"
              />
              {formik.touched.qty && formik.errors.qty && (
                <div className="error-msg">{formik.errors.qty}</div>
              )}
            </div>
            <div className="grouping-with-errorbox">
              <select
                name="unit"
                className="unit"
                aria-label="Unit"
                onChange={formik.handleChange}
                placeholder="Unit*"
                value={formik.values.unit}
                onBlur={formik.handleBlur}
              >
                <option value="">Select Unit*</option>
                <option value="ml">ml</option>
                <option value="gm">g</option>
              </select>
              {formik.touched.unit && formik.errors.unit && (
                <div className="error-msg">{formik.errors.unit}</div>
              )}
            </div>
          </div>
          <div className="division">
            <div className="grouping-with-errorbox">
              <input
                name="price"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.price}
                className="price"
                placeholder="Discounted Price*"
                type="number"
              />
              {formik.touched.price && formik.errors.price && (
                <div className="error-msg">{formik.errors.price}</div>
              )}
            </div>
            <div className="grouping-with-errorbox">
              <input
                name="ori_price"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.ori_price}
                className="original-price"
                placeholder="Original Price"
                type="number"
              />
              {formik.touched.ori_price && formik.errors.ori_price && (
                <div className="error-msg">{formik.errors.ori_price}</div>
              )}
            </div>
          </div>
          <div className="division">
            <div className="grouping-with-errorbox">
              <TagsInput
                name="topNote"
                onChange={(newTags) => formik.setFieldValue("topNote", newTags)}
                onBlur={formik.handleBlur}
                value={formik.values.topNote}
                placeHolder="Top Note"
              />
              <em>Press enter to add new note</em>
              {formik.touched.topNote && formik.errors.topNote && (
                <div className="error-msg">{formik.errors.topNote}</div>
              )}
            </div>
            <div className="grouping-with-errorbox">
              <TagsInput
                name="heartNote"
                onChange={(newTags) =>
                  formik.setFieldValue("heartNote", newTags)
                }
                onBlur={formik.handleBlur}
                value={formik.values.heartNote}
                placeHolder="Heart Note"
              />
              <em>Press enter to add new note</em>
              {formik.touched.heartNote && formik.errors.heartNote && (
                <div className="error-msg">{formik.errors.heartNote}</div>
              )}
            </div>
            <div className="grouping-with-errorbox">
              <TagsInput
                name="baseNote"
                onChange={(newTags) =>
                  formik.setFieldValue("baseNote", newTags)
                }
                onBlur={formik.handleBlur}
                value={formik.values.baseNote}
                placeHolder="Base Note"
              />
              <em>Press enter to add new note</em>

              {formik.touched.baseNote && formik.errors.baseNote && (
                <div className="error-msg">{formik.errors.baseNote}</div>
              )}
            </div>
          </div>
          <div className="grouping-with-errorbox">
            <select
              name="listed_in"
              className="listed-in"
              aria-label="Listed in"
              onChange={formik.handleChange}
              value={formik.values.listed_in}
              onBlur={formik.handleBlur}
              placeholder="Listed in*"
            >
              <option value="">Select Listed in (optional)</option>
              <option value="new collections">New Collections</option>
              <option value="special collections">Special Collections</option>
              <option value="collections">Collections</option>
            </select>
            {formik.touched.listed_in && formik.errors.listed_in && (
              <div className="error-msg">{formik.errors.listed_in}</div>
            )}
          </div>
          <div className="grouping-with-errorbox">
            <input
              name="imageFiles"
              placeholder="Images*"
              onChange={handleImageChange}
              onBlur={formik.handleBlur}
              className="images"
              type="file"
              ref={fileInputRef}
              multiple
              accept="image/*"
            />
            {formik.touched.imageFiles && formik.errors.imageFiles && (
              <div className="error-msg">{formik.errors.imageFiles}</div>
            )}
          </div>
          {/* Display thumbnails of selected images */}
          <div className="image-thumbnails">
            {selectedImages.map((image, index) => (
              <div key={index} className="thumbnail">
                <img
                  src={URL.createObjectURL(image)}
                  alt={`Thumbnail ${index}`}
                  width="100"
                  height="100"
                />
                <TiDelete
                  onClick={() => removeImage(index)}
                  className="remove-button"
                  size={"1.5vw"}
                  color={"red"}
                  cursor={"pointer"}
                />
              </div>
            ))}
          </div>

          <button
            className="submit-button"
            disabled={formik.isSubmitting}
            type="submit"
          >
            {formik.isSubmitting ? "Processing..." : "Submit"}
          </button>
        </div>
      </form>
    </>
  );
};

export default AddProduct;
