import React, { useEffect} from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import useProductStore from "../Store/productStore";
import useUtilsStore from "../Store/utilsStore";
import { useIntl } from "react-intl";
import StarRating from "../Components/starRating";
import { Formik, Field, ErrorMessage, Form } from "formik";
import reviewValidationSchema from "../Schemas/reviewValidationSchema";
import { cloudinary } from "../Utils/CloudinarySetup";
import { AdvancedImage, lazyload, placeholder } from "@cloudinary/react";
import { toast } from "react-toastify";
import "../Styles/RatingAndReview/RatingAndReview.css";

const RatingAndReview = () => {
  const { productByIdForReview, getProductByIdForReview,userOrders,getUserOrders } = useProductStore();
  
  const navigate = useNavigate();
  const { id } = useParams();
  const { isRtl } = useUtilsStore();
  const intl = useIntl();
  useEffect(() => {
    getProductByIdForReview(id);
  }, [id, getProductByIdForReview]);

  useEffect(() => {
    getUserOrders();
  },[getUserOrders]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleStarRating = (value) => {
    axios
      .post(
        `/api/ratingsAndReviews/add-rating`,
        { productId: id, rating: value },
        {
          headers: {
            Authorization: `access-token ${localStorage.getItem(
              "alambraAccessToken"
            )}`,
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          toast.success(res.data.message);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const initialValues = {
    review: productByIdForReview?.review ?? "",
  };

  const handleSubmit = (values) => {
    axios
      .post(
        `/api/ratingsAndReviews/add-review`,
        { productId: id, review: values.review },
        {
          headers: {
            Authorization: `access-token ${localStorage.getItem(
              "alambraAccessToken"
            )}`,
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          toast.success(res.data.message);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  return(
    userOrders && userOrders.some((order) => order.product.some((item) => item.productId === id)) ? 
      ( <section>
      <div className="ratings-and-reviews-head">
        <h4>{intl.formatMessage({ id: "Ratings&Reviews.title" })}</h4>
        
        { productByIdForReview && (
          <div className="product-detail">
            <div className="name-and-rating">
              <p>
                {isRtl
                  ? productByIdForReview?.name_ar
                  : productByIdForReview?.name_en}
              </p>
              <div className="rating-detail">
                <p>{productByIdForReview?.average_rating}</p>
                <p>({productByIdForReview?.ratingCount})</p>
              </div>
            </div>
            <div
              className="image-container"
              onClick={() => {
                navigate(`/product/${id}`);
              }}
            >
              <AdvancedImage
                cldImg={cloudinary
                  .image(productByIdForReview?.images[0]?.publicId)
                  .format("auto")
                  .quality("auto")}
                plugins={[lazyload(), placeholder({ mode: "blur" })]}
                alt=""
              />
            </div>
          </div>
        )}
      </div>
      <div className="rating-section">
        <h5>{intl.formatMessage({ id: "Ratings&Reviews.ratinghead" })}</h5>
        <StarRating
          initialRating={productByIdForReview?.rating ?? 5}
          readonly={false}
          onChange={(value) => handleStarRating(value)}
        />
      </div>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={reviewValidationSchema}
      >
        <Form className="review-form">
          <div className="review-section">
            <h5>{intl.formatMessage({ id: "Ratings&Reviews.reviewshead" })}</h5>
            <Field
              as="textarea"
              name="review"
              placeholder="Description..."
              rows={8}
            />
            <ErrorMessage name="review" component="div" />
          </div>

          <div className="submit-section">
            <button type="submit">
              {intl.formatMessage({ id: "Ratings&Reviews.submit" })}
            </button>
          </div>
        </Form>
      </Formik>
    </section>
    ) : (
      <div className="not-purchased">
       <h5>You haven't purchased the product yet !</h5>
       <h6>Please purchase the product and come back to review!</h6>
      </div>
     ) 
  );
};

export default RatingAndReview;
