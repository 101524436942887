import React, { useState,useEffect } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { cloudinary } from "../Utils/CloudinarySetup";
import { AdvancedImage, lazyload } from "@cloudinary/react";
import useAdminStore from "../Store/adminStore";  
import { FcGallery,FcPlus,FcAddDatabase, FcDepartment,FcInTransit, FcGrid } from "react-icons/fc";

import "../Styles/AdminNavBar/AdminNavBar.css";
import "../vendor/bootstrap/css/bootstrap.min.css";
import "../Styles/AdminTemplate/AdminTemplate.css";
import "../vendor/bootstrap-icons/bootstrap-icons.css";
import "../vendor/boxicons/css/boxicons.min.css";
import "../vendor/quill/quill.snow.css";
import "../vendor/quill/quill.bubble.css";
import "../vendor/remixicon/remixicon.css";
import "../vendor/simple-datatables/style.css";
import "../Styles/AdminTemplate/AdminTemplate.css";


const AdminNavbar = () => {
  const {
    loggedInAdmin,
    getLoggedInAdmin,
    // loggedInAdminError,
    // loggedInAdminLoading,
  } = useAdminStore();
  const navigate = useNavigate();
  useEffect(() => {
    getLoggedInAdmin();
  }, [getLoggedInAdmin]);

  const handleLogout = () => {
    localStorage.removeItem("alambraAdminAccessToken");
    localStorage.removeItem("alambraAdminRefreshToken");
    getLoggedInAdmin();
    navigate("/admin/login");
  };

  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  
  return (
    <>
      <header id="header" className="adminNavbar header">
        <div className="">
          <i onClick={toggleSidebar} className="bi bi-list toggle-sidebar-btn"></i>
        </div>

        <div className="menu-logo-container m-auto">
          <AdvancedImage
            className="logo"
            onClick = {() => navigate("/admin/dashboard")}
            cldImg={cloudinary.image(
              "alambra stock images/qqcvhdxhttpyacpud7dj"
            )}
            plugins={[lazyload()]}
            alt="logo" 
            style={{cursor:"pointer"}}
          />
          <h6>ADMIN</h6>
        </div>

        <nav className="header-nav">
          <ul className="d-flex align-items-center">
            <li className="nav-item dropdown">
              <button
                className="nav-link nav-icon"
                href="#"
                data-bs-toggle="dropdown"
              >
                <i className="bi bi-bell"></i>
                <span className="badge bg-primary badge-number">4</span>
              </button>
              <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow notifications">
                <li className="dropdown-header">
                  You have 4 new notifications
                  <button href="#">
                    <span className="badge rounded-pill bg-primary p-2 ms-2">
                      View all
                    </span>
                  </button>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li className="notification-item">
                  <i className="bi bi-exclamation-circle text-warning"></i>
                  <div>
                    <h4>Lorem Ipsum</h4>
                    <p>Quae dolorem earum veritatis oditseno</p>
                    <p>30 min. ago</p>
                  </div>
                </li>

                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li className="notification-item">
                  <i className="bi bi-x-circle text-danger"></i>
                  <div>
                    <h4>Atque rerum nesciunt</h4>
                    <p>Quae dolorem earum veritatis oditseno</p>
                    <p>1 hr. ago</p>
                  </div>
                </li>

                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li className="notification-item">
                  <i className="bi bi-check-circle text-success"></i>
                  <div>
                    <h4>Sit rerum fuga</h4>
                    <p>Quae dolorem earum veritatis oditseno</p>
                    <p>2 hrs. ago</p>
                  </div>
                </li>

                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li className="notification-item">
                  <i className="bi bi-info-circle text-primary"></i>
                  <div>
                    <h4>Dicta reprehenderit</h4>
                    <p>Quae dolorem earum veritatis oditseno</p>
                    <p>4 hrs. ago</p>
                  </div>
                </li>

                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li className="dropdown-footer">
                  <button href="#">Show all notifications</button>
                </li>
              </ul>
            </li>

            <li className="nav-item dropdown pe-3">
              <button
                className="nav-link nav-profile d-flex align-items-center pe-0"
                
                data-bs-toggle="dropdown"
              >
                <span className="d-none d-md-block dropdown-toggle ps-2">
                  {loggedInAdmin ? loggedInAdmin.email : null}
                </span>
              </button>
              <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                
                
              
                
              
             
                <li>
                  <button
                    className="dropdown-item d-flex align-items-center"
                    onClick={handleLogout}
                  >
                    <i className="bi bi-box-arrow-right"></i>
                    <span>Sign Out</span>
                  </button>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </header>
      <aside id="sidebar" className={`sidebar ${isSidebarVisible ? 'visible' : ''}`}>
        <p className="close-btn" onClick={toggleSidebar}>Close</p>
        <ul className="sidebar-nav" id="sidebar-nav">
          <li onClick={toggleSidebar} className="nav-item">
            <NavLink
              className={"nav-link"}
              to="/admin/dashboard"
              activeClassName="active"
            >
              <FcGrid size={25} style={{ marginRight: "5px" }} />
              <span>Dashboard</span>
            </NavLink>
          </li>

          <li onClick={toggleSidebar} className="nav-item">
            <NavLink
              className={"nav-link"}
              to="/admin/all-products"
              activeClassName="active"
            >
              <FcDepartment size={25} style={{ marginRight: "5px" }}/>
              <span>All Products</span>
            </NavLink>
          </li>

          <li onClick={toggleSidebar} className="nav-item">
            <NavLink
              className="nav-link"
              to="/admin/all-orders"
              activeClassName="active"
            >
              <FcInTransit size={25} style={{ marginRight: "5px" }}/>
              <span>All Orders</span>
            </NavLink>
          </li>

          <li onClick={toggleSidebar} className="nav-item">
            <NavLink
              className="nav-link"
              to="/admin/add-product"
              activeClassName="active"
            >
              {/* <i class="bi bi-grid"></i> */}
              <FcPlus size={25} style={{ marginRight: "5px" }}/>
              <span>Add Product</span>
            </NavLink>
          </li>

          <li onClick={toggleSidebar} className="nav-item">
            <NavLink
              className={"nav-link"}
              to="/admin/add-admin"
              activeClassName="active"
            >
              {/* <i class="bi bi-grid"></i> */}
              <FcAddDatabase size={25} style={{ marginRight: "5px" }}/>
              <span>Add Admin</span>
            </NavLink>
          </li>

          <li onClick={toggleSidebar} className="nav-item">
            <NavLink
              className={"nav-link"}
              to="/admin/banner-images"
              activeClassName="active"
            >
              {/* <i class="bi bi-grid"></i> */}
              <FcGallery size={25}  style={{ marginRight: "5px" }}/>
              <span>Banner Images</span>
            </NavLink>
          </li>
        </ul>
      </aside>
    </>
  );
};

export default AdminNavbar;