export const createItemObject = (product, quantity) => ({
    title: `${product?.name_en} ${product?.category_en} ${product?.quantity}`,
    description: product?.description_en,
    quantity: quantity, // Set the quantity based on the context
    unit_price: product?.price.toFixed(2), // Ensure price is in string format with 2 decimals
    discount_amount: "0.00",
    reference_id: product?._id,
    image_url: `https://alambraperfumes.com/${product?.rawImages[0]?.path}`,
    product_url: `https://alambraperfumes.com/product/${product?._id}`,
    gender: "Not applicable",
    category: product?.category_en,
    color: "Not applicable",
    product_material: "Not applicable",
    size_type: product?.quantity?.match(/[a-zA-Z]+/)[0],
    size: product?.quantity?.match(/\d+/)[0],
    brand: "Alambra Perfumes",
  });