import React, { useEffect, useState } from "react";
import useProductStore from "../Store/productStore";
import { useIntl } from "react-intl";
import useUtilsStore from "../Store/utilsStore";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "../Styles/Orders/Orders.css";
import { BeatLoader } from "react-spinners";
import { HiArrowCircleUp, HiArrowCircleDown } from "react-icons/hi";
import Swal from "sweetalert2";

export const Orders = () => {
  const { userOrders, getUserOrders, userOrdersLoading, userOrdersError } =
    useProductStore();
  const { loggedInUser, getLoggedInUser } = useUtilsStore();
  const Server = process.env.REACT_APP_SERVER;
  const navigate = useNavigate();
  const intl = useIntl();
  const { isRtl } = useUtilsStore();
  useEffect(() => {
    getLoggedInUser();
    getUserOrders();
  }, [getUserOrders, getLoggedInUser]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isExpandedMap, setIsExpandedMap] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const toggleExpansion = (orderId) => {
    setIsExpandedMap((prevExpandedMap) => ({
      ...prevExpandedMap,
      [orderId]: !prevExpandedMap[orderId],
    }));
  };
  const handleOrder = async (orderId, productId, action) => {
    try {
      Swal.fire({
        title: "Are you sure to cancel this order?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, cancel it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          setIsLoading(true);
          const res = await axios.patch(
            `/api/orders/update/${action}/${orderId}`,
            { productId },
            {
              headers: {
                Authorization: `access-token ${localStorage.getItem(
                  "alambraAccessToken"
                )}`,
              },
            }
          );
          if (res.data.success) {
            Swal.fire({
              icon: "success",
              title: res.data.message,
            }).then((result) => {
              if (result.isConfirmed) {
                getUserOrders();
              }
            });
          }
        }
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: error.response.data.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="orders-container">
      {userOrders?.length > 0 ? (
        userOrders?.[0]?.userId === loggedInUser?._id ? (
          <div className="all-orders">
            {userOrders?.map((orders, orderIndex) =>
              orders?.product?.map((order, index) => (
                <div className="order-container">
                  <div
                    key={index}
                    className={`order ${
                      isExpandedMap[order._id] ? "expanded" : ""
                    }`}
                  >
                    <div className="product">
                      <div className="image-container">
                        <img
                          src={`${Server}${order?.productDetails?.rawImages[0]?.path}`}
                          alt=""
                          onClick={() =>
                            navigate(`/product/${order.productId}`)
                          }
                        />
                      </div>

                      <h6>
                        {isRtl
                          ? order.productDetails.name_ar
                          : order.productDetails.name_en}
                      </h6>
                    </div>
                    <h6 className="price">
                      AED {order?.price}
                      {orders?.tax && " + Tax "}
                    </h6>
                    <h6 className="quantity">{order.quantity}</h6>
                    <div className="status">
                      <h6>
                        {intl.formatMessage({ id: "order.order" })}{" "}
                        {order.order_status}{" "}
                        {intl.formatMessage({ id: "order.on" })}{" "}
                        {new Date(
                          order.order_status === "confirmed"
                            ? order.order_date
                            : order.order_status === "shipped"
                            ? order.shipped_date
                            : order.order_status === "out for delivery"
                            ? order.out_for_delivery_date
                            : order.order_status === "delivered"
                            ? order.delivery_date
                            : order.order_status === "cancelled"
                            ? order.cancelled_date
                            : null
                        ).toLocaleDateString("en-US", {
                          weekday: "short",
                          day: "numeric",
                          month: "short",
                          year: "numeric",
                        })}
                      </h6>
                      <p>
                        {intl.formatMessage({ id: "order.status.pretext" })}{" "}
                        {order.order_status}{" "}
                      </p>
                      <Link to={`/rating-and-review/${order.productId}`}>
                        {intl.formatMessage({ id: "order.link" })}
                      </Link>
                    </div>

                    {isExpandedMap[order._id] ? (
                      <HiArrowCircleDown
                        size={"2vw"}
                        cursor={"pointer"}
                        className="expand-button"
                        onClick={() => toggleExpansion(order._id)}
                      />
                    ) : (
                      <HiArrowCircleUp
                        size={"2vw"}
                        cursor={"pointer"}
                        className="expand-button"
                        onClick={() => toggleExpansion(order._id)}
                      />
                    )}
                  </div>
                  {isExpandedMap[order._id] && (
                    <div className="additional-data">
                      <div className="divider">
                        <h6>Order ID : {orders.order_no}</h6>
                        {/* <h6>Suborder ID : {order._id}</h6> */}
                        <h6>
                          Payment method :{" "}
                          {order.payment_method === "cashOnDelivery"
                            ? "Cash On Delivery"
                            : order.payment_method}
                        </h6>
                        <h6>
                          Order Date :
                          {new Date(order.order_date).toLocaleDateString(
                            "en-US",
                            {
                              weekday: "short",
                              day: "numeric",
                              month: "short",
                              year: "numeric",
                            }
                          )}
                        </h6>
                      </div>

                      <h6 className="address">
                        Delivery Address :{" "}
                        <span>{order.shipping_address.name},</span>
                        <span>{order.shipping_address.addressLine1},</span>
                        {order.shipping_address.addressLine2 && (
                          <span>{order.shipping_address.addressLine2},</span>
                        )}
                        {order.shipping_address.city && (
                          <span>{order.shipping_address.city},</span>
                        )}
                        {order.shipping_address.state && (
                          <span>{order.shipping_address.state},</span>
                        )}
                        {order.shipping_address.postalCode && (
                          <span>{order.shipping_address.postalCode},</span>
                        )}
                        <span>{order.shipping_address.country}</span>
                      </h6>
                      <div className="divider">
                        <h6>Phone : {order.phone}</h6>
                        {order?.email && <h6>Email : {order?.email}</h6>}
                      </div>
                      <div className="btn-cancel-return">
                        {
                          ["confirmed", "shipped"].includes(
                            order.order_status
                          ) && (
                            <button
                              id="cancel-btn"
                              onClick={() =>
                                handleOrder(
                                  orders._id,
                                  order.productId,
                                  "cancel"
                                )
                              }
                              disabled={isLoading}
                            >
                              {isLoading ? (
                                <BeatLoader
                                  size={8}
                                  color={"#ffffff"}
                                  loading={isLoading}
                                />
                              ) : (
                                "Cancel Order"
                              )}
                            </button>
                          )
                          //  : order.order_status === "delivered" &&
                          //   (new Date() - new Date(order.order_date)) /
                          //     (1000 * 60 * 60 * 24) <=
                          //     7 ? (
                          //   <button
                          //     onClick={() => handleOrder(orders._id,order.productId, "return")}
                          //   >
                          //     return
                          //   </button>
                          // ) : order.order_status === "cancelled" ? null : null
                        }
                      </div>
                    </div>
                  )}
                </div>
              ))
            )}
          </div>
        ) : (
          userOrdersLoading && (
            <div
              className="loading"
              style={{
                height: "50vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <BeatLoader color="#3498db" size={"1.5vw"} />
            </div>
          )
        )
      ) : userOrdersLoading ? (
        <div
          className="loading"
          style={{
            height: "50vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <BeatLoader color="#3498db" size={"1.5vw"} />
        </div>
      ) : (
        (userOrdersError?.response?.status === 404 ||
          userOrders?.length === 0) && (
          <div className="empty-order">
            <h3>You haven't ordered anything yet!</h3>
          </div>
        )
      )}
    </div>
  );
};
