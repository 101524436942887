import React, { useEffect, useState } from "react";
import axios from "axios";
import { BeatLoader } from "react-spinners";
import { Outlet, Navigate } from "react-router-dom";


const PrivateRoutes = () => {
  const accessToken = localStorage.getItem("alambraAccessToken");
  const refreshToken = localStorage.getItem("alambraRefreshToken");
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  
  useEffect(() => {
      
     if (!accessToken && !refreshToken) {
      setIsAuthenticated(false);
      return;
    }
    const checkAuthentication = async () => {
    const response = await axios.get(`/api/tokens/authenticate`, {
        headers: {
          "accesstoken": `Bearer ${accessToken}`,
          "refreshtoken": `Bearer ${refreshToken}`,
        }
      })
      if (response.data.success && response.status === 200) {
        setIsAuthenticated(true);
        
      } else if (response.data.success && response.status === 201) {
        localStorage.setItem("alambraAccessToken", response.data.accessToken)
        setIsAuthenticated(true);
      }
      else{
        setIsAuthenticated(false);
        return;
      }
    }
    checkAuthentication();
    
  },[accessToken,refreshToken]);


  

  if (isAuthenticated === null) {
    return <div className="loading" style={{height: "50vh", display: "flex", justifyContent: "center", alignItems: "center"}}>
      <BeatLoader color="#3498db"  size={"1.5vw"} />
    </div>
  }

  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoutes;
