import jsPDF from "jspdf";
import "jspdf-autotable";
const  arabicPattern = /[\u0600-\u06FF]/;

const InvoiceGen = async(order) => {
  
    function updateState(address) {
      if (arabicPattern.test(address.state)) {
        if (address.state.includes("دبيّ")) {
          address.state = "Dubai";
        }
        if (address.state.includes("أبو ظبي")) {
          address.state = "Abu Dhabi";
        }
        if (address.state.includes("الفجيرة")) {
          address.state = "Fujairah";
        }
        if (address.state.includes("الشارقةّ")) {
          address.state = "Sharjah";
        }
        if (address.state.includes("ام القيوين")) {
          address.state = "Umm Al Quwain";
        }
        if (address.state.includes("رأس الخيمة")) {
          address.state = "Ras Al Khaimah";
        }
        if (address.state.includes("عجمان")) {
          address.state = "Ajman";
        }
      }
    }

    const billedTo = order?.product[0].billing_address
    ? order?.product[0].billing_address
    : order.product[0].shipping_address;
   
    const shippingTo = order?.product[0].shipping_address
    updateState(billedTo);
    updateState(shippingTo);
   

  const generateInvoice = async () => {
    const pdf = new jsPDF();

    // Add header section with company logo, invoice number, and billed to address
    const logoUrl = "../../assets/images/LOGO1.png"; // Replace with your image link

    const imageWidth = 20; // Adjust the width of the image as needed
    const imageHeight = 20; // Adjust the height of the image as needed
    const pdfWidth = pdf.internal.pageSize.width;

    // Calculate the x-coordinate for centering the image
    const xCoordinate = (pdfWidth - imageWidth) / 2;

    pdf.addImage(logoUrl, "PNG", xCoordinate, 5, imageWidth, imageHeight);
    // Add heading "Invoice" beneath the logo
    pdf.setFont("helvetica", "bold");
    const heading = "INVOICE";
    const headingWidth =
      (pdf.getStringUnitWidth(heading) * pdf.internal.getFontSize()) /
      pdf.internal.scaleFactor; // Calculate width of the heading text
    const headingXCoordinate = (pdfWidth - headingWidth) / 2; // Center horizontally

    pdf.text(heading, headingXCoordinate, 35);

    // Reset font to normal (optional)
    pdf.setFont("helvetica", "normal");
    pdf.setFontSize(10);

     // Add order details section
    const orderNumberLines = pdf.splitTextToSize(
      `Order Number: ${order?.order_no}`,
      80
    );
    pdf.text(orderNumberLines, 10, 45);

    const OrderDate = new Date(
      order?.product[0]?.order_date
    ).toLocaleDateString("en-US", {
      weekday: "short",
      day: "numeric",
      month: "short",
      year: "numeric",
    });

    pdf.text(`Order Date: ${OrderDate}`, 10, 50);

    const paymentMethodLines = pdf.splitTextToSize(
      `Payment Method: ${order?.product[0]?.payment_method}`,
      80
    );
    pdf.text(paymentMethodLines, 10, 55);

    
    pdf.text(`Invoice Number: ${order?.invoice_no}`, 140, 45);
    pdf.text(
      new Date().toLocaleDateString("en-US", {
        weekday: "short",
        day: "numeric",
        month: "short",
        year: "numeric",
      }),
      140,
      50
    );
    const billingAddressLines = pdf.splitTextToSize(
      `Billed To:\n${billedTo?.name},\n${billedTo?.addressLine1},${
        billedTo?.addressLine2 ? "\n" + billedTo?.addressLine2 + "," : ""
      }\n${billedTo?.city ? billedTo?.city + "," : ""}${
        billedTo?.state ?? ""
      },${billedTo?.postalCode ? billedTo?.postalCode + "," : ""} ${
        billedTo?.country ?? ""
      }\nph: ${order?.product[0]?.phone}`,
      60
    );
    pdf.text(billingAddressLines, 10, 65);

    const shippingAddressLines = pdf.splitTextToSize(
      `Shipping To:\n${shippingTo?.name},\n${shippingTo?.addressLine1},${
        shippingTo?.addressLine2 ? "\n" + shippingTo?.addressLine2 + "," : ""
      }\n${shippingTo?.city ? shippingTo?.city + "," : ""}${
        shippingTo?.state ?? ""
      },${shippingTo?.postalCode ? shippingTo?.postalCode + "," : ""} ${
        shippingTo?.country ?? ""
      }\nph: ${order?.product[0]?.phone}`,
      60
    );
    pdf.text(shippingAddressLines, 140, 65);
      
    // Add table for product details
    const columns = ["Product Name", "Price", "Quantity", "Subtotal"];
    const rows = order?.product?.map((item) => [
      `${item?.productDetails?.name_en}\n${item?.productDetails?.category_en} ${item?.productDetails?.quantity}`,
      `AED ${item?.price}`,
      item?.quantity,
      `AED ${
        Number(item?.price) *
        Number(item?.quantity)
      }`,
    ]);
    pdf.autoTable({
      head: [columns],
      body: rows,
      startY: 95,
    });

    // Add section for displaying the total
    pdf.text(
      `Shipping: ${order?.shipping_charge === 0 ? "Free" :  order?.shipping_charge ? "AED " + order?.shipping_charge : "Free"}`,
      150,
      pdf.autoTable.previous.finalY + 10
    );
    order?.tax && pdf.text(
      `Tax: AED ${order?.tax}`,
      150,pdf.autoTable.previous.finalY + 15
    )
    pdf.setFont("helvetica", "bold");
    pdf.text(
      `Total: AED ${order?.total}`,
      150,
      order?.tax ? pdf.autoTable.previous.finalY + 20 : pdf.autoTable.previous.finalY + 15
    );
    pdf.setFont("helvetica", "normal");
    pdf.text("Thank you!", 15, pdf.autoTable.previous.finalY + 25);
    pdf.save(`invoice_${order?.invoice_no}.pdf`);
  };


  // Call the function to generate the invoice
   await generateInvoice();
   return true;
};

export default InvoiceGen;
