import React, { useEffect, useMemo } from "react";
import useAdminStore from "../Store/adminStore";
import { useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { Box, Button, Typography, Switch } from "@mui/material";
import { MdModeEditOutline, MdDelete } from "react-icons/md";
import Swal from "sweetalert2";
import axios from "axios";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

const AdminAllProductsTable = () => {
  const { allProducts, getAllProducts, allProductsLoading } = useAdminStore();
  const navigate = useNavigate();
  const SERVER = process.env.REACT_APP_SERVER;

  useEffect(() => {
    getAllProducts();
  }, [getAllProducts]);

  const handleDelete = (id, name, category, quantity) => {
    Swal.fire({
      title: `Delete ${name} ${category} ${quantity} from the database?`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`/api/admin/products/delete/${id}`, {
            headers: {
              Authorization: `admin-access-token ${localStorage.getItem(
                "alambraAdminAccessToken"
              )}`,
            },
          })
          .then((res) => {
            if (res.data.success) {
              Swal.fire(
                "Deleted!",
                `${name} ${category} ${quantity} is ${res.data.message}.`,
                "success"
              );
              window.location.reload();
            }
          });
      }
    });
  };

  const columns = useMemo(() => {
    const handleEdit = (id) => {
      navigate(`/admin/edit-product/${id}`);
    };

    const handleChange = async (id, value) => {
      const response = await axios.put(
        `/api/admin/products/toggle-stock/${id}/${value}`,
        {},
        {
          headers: {
            Authorization: `admin-access-token ${localStorage.getItem(
              "alambraAdminAccessToken"
            )}`,
          },
        }
      );
      if (response.data.success) {
        Swal.fire({
          icon: "success",
          title: response.data.message,
        });
        getAllProducts();
      }
    };
    return [
      {
        id: "thumbnail",
        header: "Thumbnail",
        accessorKey: "thumbnail",
        Cell: ({ row }) => (
          <img
            src={row.original.thumbnail}
            alt={row.original.name}
            style={{
              width: "100px",
              height: "100px",
              objectFit: "cover",
              borderRadius: "50%",
            }}
            loading="lazy"
          />
        ),
      },
      {
        id: "name",
        header: "Name",
        accessorKey: "name",
      },
      {
        id: "category",
        header: "Category",
        accessorKey: "category",
      },
      {
        id: "price",
        header: "Price",
        accessorKey: "price",
      },
      {
        id: "quantity",
        header: "Quantity",
        accessorKey: "quantity",
      },
      {
        id: "in_stock",
        header: "In Stock",
        accessorKey: "in_stock",
        Cell: ({ row }) => (
          <Switch
            checked={row.original.in_stock}
            onChange={() =>
              handleChange(row.original.id, row.original.in_stock)
            }
            color="info"
            inputProps={{ "aria-label": "controlled" }}
          />
        ),
      },
      {
        id: "actions",
        header: "Actions",
        accessorKey: "actions",
        Cell: ({ row }) => (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Button
              onClick={() => handleEdit(row.original.id)}
              sx={{
                border: "none",
                backgroundColor: "transparent",
                display: "flex",
              }}
            >
              {" "}
              <MdModeEditOutline color="#3498db" cursor={"pointer"} />
            </Button>

            <Button
              onClick={() =>
                handleDelete(
                  row.original.id,
                  row.original.name,
                  row.original.category,
                  row.original.quantity
                )
              }
              style={{
                border: "none",
                backgroundColor: "transparent",
                display: "flex",
              }}
            >
              <MdDelete color="red" cursor={"pointer"} />
            </Button>
          </Box>
        ),
      },
    ];
  }, [getAllProducts, navigate]);

  const mappedData = useMemo(() => {
    const rows = [];
    allProducts?.forEach((product) => {
      rows.push({
        id: product._id,
        name: product.name_en,
        category: product.category_en,
        description: product.description_en,
        price: `AED ${product.price}`,
        quantity: product.quantity,
        thumbnail: `${SERVER}${product?.rawImages?.[0]?.path}`,
        notes: product.notes,
        actions: product._id,
        in_stock: product?.out_of_stock === true ? false : true,
      });
    });
    return rows;
  }, [SERVER, allProducts]);

  const table = useMaterialReactTable({
    columns,
    data: mappedData || [],
    enableColumnFilterModes: true,
    enableGrouping: true,
    enableColumnPinning: true,
    enableFacetedValues: true,
    initialState: {
      showColumnFilters: false,
      showGlobalFilter: true,
      columnPinning: {
        left: ["mrt-row-expand", "mrt-row-select"],
        right: ["mrt-row-actions"],
      },
    },
    paginationDisplayMode: "pages",
    positionToolbarAlertBanner: "bottom",
    muiSearchTextFieldProps: {
      size: "small",
      variant: "outlined",
    },
    muiPaginationProps: {
      color: "secondary",
      rowsPerPageOptions: [10, 20, 30],
      shape: "rounded",
      variant: "outlined",
    },
    renderDetailPanel: ({ row }) => (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          gap: "30px",

          maxWidth: "1000px",

          width: "100%",
        }}
      >
        <img
          alt="avatar"
          height={200}
          src={row.original.thumbnail}
          loading="lazy"
        />
        <Box sx={{ textAlign: "center" }}>
          <Typography sx={{ fontWeight: "bold" }} variant="h6">
            Description:
          </Typography>
          <Typography variant="body1">{row.original.description}</Typography>
        </Box>
        <Box sx={{ textAlign: "center" }}>
          <Typography sx={{ fontWeight: "bold" }} variant="h6">
            Notes:
          </Typography>
          {row.original.notes && (
            <Box sx={{ display: "flex", gap: "30px" }}>
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
              >
                <Typography sx={{ fontWeight: "bold" }} variant="body1">
                  Top:
                </Typography>
                {row.original.notes.top.map((note, index) => (
                  <Typography key={index} variant="body1">{note}</Typography>
                ))}
              </Box>
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
              >
                <Typography sx={{ fontWeight: "bold" }} variant="body1">
                  Heart:
                </Typography>
                {row.original.notes.heart.map((note) => (
                  <Typography variant="body1">{note}</Typography>
                ))}
              </Box>
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
              >
                <Typography sx={{ fontWeight: "bold" }} variant="body1">
                  Base:
                </Typography>
                {row.original.notes.base.map((note) => (
                  <Typography variant="body1">{note}</Typography>
                ))}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    ),
  });

  return (
    <Box style={{ padding: "5vw" }}>
      {allProductsLoading ? (
        <div
          className="loading"
          style={{
            height: "50vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <BeatLoader color="#3498db" size={"1.5vw"} />
        </div>
      ) : (
        <>
          <Box>
            <Typography
              sx={{ fontWeight: "bold", margin: 0, marginBottom: "10px" }}
              variant="h5"
            >
              All Products
            </Typography>
            <MaterialReactTable table={table} />
          </Box>
        </>
      )}
    </Box>
  );
};

const AdminAllProductsPage = () => (
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <AdminAllProductsTable />
  </LocalizationProvider>
);

export default AdminAllProductsPage;
