import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useFormik } from "formik";
import AdminBannerValidationSchema from "../Schemas/AdminBannerSchema";
import useAdminStore from "../Store/adminStore";
import { TiDelete } from "react-icons/ti";
import Swal from "sweetalert2";
import "../Styles/AdminBanner/AdminBanner.css";

const AdminBannerImages = () => {
  const fileInputRef = useRef(null);

  const { bannerImages, getBannerImages, bannerImagesLoading } =
    useAdminStore();
  const Server = process.env.REACT_APP_SERVER;
  const [selectedOption, setSelectedOption] = useState("head_banner");
  const [existingImages, setExistingImages] = useState();
  const [selectedImages, setSelectedImages] = useState([]);

  useEffect(() => {
    const fetchBannerImages = async () => {
      getBannerImages();
    };
    fetchBannerImages(); // Call the function to fetch banner images on page load
  }, [getBannerImages]); // Run the effect when getBannerImages or bannerImages changes

  useEffect(() => {
    setExistingImages(bannerImages?.head_banner);
  }, [bannerImages]);

  const deleteImage = (url) => {
    setExistingImages(existingImages.filter((image) => image.url !== url));
    formik.setFieldValue("deleteExisting", true);
    formik.setFieldValue("deleteLocation", selectedOption);
    formik.values.deletingImages
      ? formik.values.deletingImages.push(url)
      : formik.setFieldValue("deletingImages", [url]);
  };

  const handleBannerChange = (event) => {
    formik.resetForm();
    setSelectedImages([]);
    fileInputRef.current.value = "";
    const option = event.target.value;
    setSelectedOption(option);
    const toArray = Array.isArray(bannerImages[option])
      ? bannerImages[option]
      : [bannerImages[option]];
    setExistingImages(toArray);
  };

  const handleImageChange = (event) => {
    const newImages = Array.from(event.currentTarget.files);
    const totalImgCount = [...existingImages, ...selectedImages, ...newImages]
      .length;
    if (selectedOption === "head_banner") {
      setSelectedImages([...selectedImages, ...newImages]);
      formik.setFieldValue("imageFiles", [
        ...formik.values.imageFiles,
        ...newImages,
      ]);
      formik.setFieldValue("fileUploadCategory", selectedOption);
    } else if (selectedOption === "banner1") {
      if (totalImgCount <= 2) {
        setSelectedImages([...selectedImages, ...newImages]);
        formik.setFieldValue("imageFiles", [
          ...formik.values.imageFiles,
          ...newImages,
        ]);
        formik.setFieldValue("fileUploadCategory", selectedOption);
      } else {
        Swal.fire({
          icon: "info",
          title: "Max limit reached",
          text: "A max total of 2 images only allowed for banner1, please delete existing to upload new. ",
          showConfirmButton: false,
          timer: 3000,
        });
        event.currentTarget.value = "";
      }
    } else {
      if (totalImgCount <= 1) {
        setSelectedImages([...selectedImages, ...newImages]);
        formik.setFieldValue("imageFiles", [
          ...formik.values.imageFiles,
          ...newImages,
        ]);
        formik.setFieldValue("fileUploadCategory", selectedOption);
      } else {
        Swal.fire({
          icon: "info",
          title: "Max limit reached",
          text: "A max total of 1 images only allowed, please delete existing to upload new. ",
          showConfirmButton: false,
          timer: 3000,
        });
        event.currentTarget.value = "";
      }
    }
  };

  const removeImage = (index) => {
    const updatedImages = [...selectedImages];
    updatedImages.splice(index, 1);
    setSelectedImages(updatedImages);

    const updatedFormikImages = [...formik.values.imageFiles];
    updatedFormikImages.splice(index, 1);
    formik.setFieldValue("imageFiles", updatedFormikImages);

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      imageFiles: [],
    },
    validationSchema: AdminBannerValidationSchema,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(false);

      const changedValues = {};
      Object.keys(values).forEach((key) => {
        if (
          JSON.stringify(values[key]) !==
          JSON.stringify(formik.initialValues[key])
        ) {
          changedValues[key] = values[key];
        }
      });

      if (
        formik.values !== formik.initialValues &&
        Object.keys(changedValues).length > 0
      ) {
        setSubmitting(true);
        const reversed = Object.fromEntries(
          Object.entries(changedValues).reverse()
        );
        const formData = new FormData();

        for (const key in reversed) {
          if (reversed.hasOwnProperty(key)) {
            const value = reversed[key];

            if (!Array.isArray(value)) {
              // Append a non-array field
              formData.append(key, value);
            } else {
              // Append an array field
              for (const item of value) {
                formData.append(key, item);
              }
            }
          }
        }
        axios
          .patch(`/api/admin/banner/update`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `admin-access-token ${localStorage.getItem(
                "alambraAdminAccessToken"
              )}`,
            },
          })
          .then(async(res) => {
            setSubmitting(false);
            if (res.data.success) {
              Swal.fire({
                icon: "success",
                title: res.data.message,
                showConfirmButton: false,
                timer: 2000,
              });
              formik.resetForm();
              fileInputRef.current.value = "";
              setSelectedImages([]);
              setExistingImages(res.data.updatedBannerImages?.[0][selectedOption]);
            }
          })
          .catch((error) => {
            setSubmitting(false);
            Swal.fire({
              icon: "error",
              title: error.response.data.message,
            });
          });
      } else {
        Swal.fire({
          icon: "info",
          title: "Nothing changed",
          text: "Please make some changes before submitting",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    },
  });
  return (
    <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
      <div className="banner-control-container">
        <label htmlFor="banner-title" className="banner-title">Select Banner</label>
        <select name="" id="banner-title" className="banner-selector" onChange={handleBannerChange}>
          <option value="head_banner">Head Banner</option>
          <option value="banner1">Banner1</option>
          <option value="banner2">Banner2</option>
          <option value="banner3">Banner3</option>
          <option value="mobile_banner">Mobile Banner</option>
        </select>
        <label htmlFor="banner-existing-images">Existing Images</label>

        <div className="banner-existing-images">
          {bannerImages && existingImages?.length > 0 ? (
            existingImages?.map((image, index) => (
              <div className="banner-existing-image" key={index}>
                <img src={`${Server}${image?.url}`} alt="" />
                <TiDelete
                  onClick={() => deleteImage(image?.url)}
                  className="banner-delete-button"
                  size={"1.5vw"}
                  color={"red"}
                  cursor={"pointer"}
                />
              </div>
            ))
          ) : bannerImagesLoading ? (
            <div>Loading...</div>
          ) : (
            <div>No images</div>
          )}
        </div>

        <div className="banner-container-grouping">
          <label htmlFor="imageFiles">Add Images</label>
          <input
            name="imageFiles"
            placeholder="Images*"
            onChange={handleImageChange}
            onBlur={formik.handleBlur}
            ref={fileInputRef}
            id="imageFiles"
            className="images"
            type="file"
            multiple
            accept="image/*"
          />

          {selectedImages.length > 0 && (
            <div>
              <span>{`${selectedImages.length} ${
                selectedImages.length === 1 ? "file" : "files"
              } selected: [${selectedImages
                .map((image) => `"${image.name}"`)
                .join(" ,  ")}]`}</span>
            </div>
          )}

          {formik.touched.imageFiles && formik.errors.imageFiles && (
            <div className="banner-error-msg">{formik.errors.imageFiles}</div>
          )}
        </div>

        {/* Display thumbnails of selected images */}
        <div className="banner-image-thumbnails">
          {selectedImages.map((image, index) => (
            <div key={index} className="banner-thumbnail">
              <img
                src={URL.createObjectURL(image)}
                alt={`Thumbnail ${index}`}
                width="auto"
                height="100"
              />
              <TiDelete
                onClick={() => removeImage(index)}
                className="thumbnail-remove-button"
                size={"1.5vw"}
                color={"red"}
                cursor={"pointer"}
              />
            </div>
          ))}
        </div>
        <span>Warning : Maximum file size should not exceed 20 MB!</span>
        <button
          className="banner-submit-button"
          disabled={formik.isSubmitting}
          type="submit"
        >
          {formik.isSubmitting ? "Processing..." : "Submit"}
        </button>
      </div>
    </form>
  );
};

export default AdminBannerImages;
