import React,{useEffect} from "react";
import "../Styles/ReturnAndRefund/ReturnAndRefund.css";

const ReturnAndRefund = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  },[])
  return (
    <div className="return-refund">
      <h4 className="return-refund_title">Return and Refund</h4>
      <h5 className="return-refund_subtitle">Return:</h5>
      <p className="return-refund_text">
        We are trying hard to serve your order to your appropriate selection. In
        case if you received the wrong product or damaged/defected product, you
        can exchange your product with delivery agent upon the same day of
        delivery.
      </p>
      <ul>
        <li>Your pickup will be done on the same day of delivery.</li>
        <li>
          All Products must be unused,and returned with the original packing &
          tags when we receive it.
        </li>
      </ul>
      <p className="return-refund_text">
        In case, the order does not meet the above conditions exchange request
        will not be processed and your product will be returned to you.
      </p>
      <p className="return-refund_text">
        {" "}
        No other product can be exchanged on behalf of the same.
      </p>
      <h4 className="return-refund_title return-refund_second-title">
        Refund Policy
      </h4>
      <h5 className="return-refund_subtitle">Amount Refund:</h5>
      <p className="return-refund_text">
        1. If you cancel the whole Order, the full amount will be refunded to
        you including shipping charges{" "}
      </p>
      <p className="return-refund_text">
        2. If you refuse to accept the shipment, the amount will be refunded.
      </p>
      <p className="return-refund_text">
        3. If your cancellation is partial , ie, you cancel only some products
        from your order, the shipping charges if any, will not be refunded, and
        only value of the cancelled products will be refunded.
      </p>
      <p className="return-refund_text">
        4. If the value of whole order before cancellation was AED 500 or above
        , and after partial cancellation it reduced to less than AED 500 , a
        shipping charge of AED 25 will be added to the order. If the
        paymentMethod is cash on delivery it will be collected at the time of
        delivery. If the payment method is any of the online methods, this
        charge will be deducted from the refund amount issued to you.
      </p>
      <h5 className="return-refund_subtitle">Mode of Refund:</h5>
      <p className="return-refund_text">
        Prepaid Orders: The amount will be refunded to the same account from
        which the order has been placed.The amount will be processed in 1-2
        working days. Your money will be reflected in your account in 5-10
        working days.
      </p>
    </div>
  );
};

export default ReturnAndRefund;
