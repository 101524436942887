import axios from "axios";
import { toast } from "react-toastify";

export let isUserShouldBeAllowed = null;
export const AllowedCountries = ["IN", "AE", "SA"];
export const RedirectingCountry = "IT";

export const userAccessAllotation = async () => {
  const userCountry = await getUserCountry();
  if (userCountry === RedirectingCountry) {
    // Redirect to the Italian website
    window.location.href = process.env.REACT_APP_ITALIAN_WEBSITE;
  } else if (AllowedCountries.includes(userCountry)) {
    isUserShouldBeAllowed = true;
  } else if (userCountry === null) {
    isUserShouldBeAllowed = true;
  } else {
    isUserShouldBeAllowed = false;
  }
};

export const getUserCountry = async () => {
  try {
    const apiUrl = `https://ipinfo.io/?token=${process.env.REACT_APP_IPINFO_ACCESS_TOKEN}`;
    const response = await axios.get(apiUrl);
    const { country } = response.data;
    return country;
  } catch (error) {
    if (error.message === "Network Error") {
      toast.warning("Please disable your shield for the best experience");
    }
    return null;
  }
};
