import React, { useState, useEffect } from 'react';
import axios from 'axios';

const RecentSales = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState('last 5');
  const [numberOfOrders, setNumberOfOrders] = useState(5);
  const [recentSalesData, setRecentSalesData] = useState();
  // eslint-disable-next-line
  const [error, setError] = useState();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleFilterClick = (event, newFilter) => {
    event.preventDefault();
    const textContent = event.target.textContent;
    setFilter(textContent);
    setNumberOfOrders(newFilter);
    setIsOpen(false);
  };

  

  useEffect(() => {
    const getRecentSalesData = () => {
      axios.get(`/api/admin/dashboardData/recentSales/${numberOfOrders}`, {
        headers: {
          Authorization: `admin-access-token ${localStorage.getItem("alambraAdminAccessToken")}`
        }
      })
        .then((res) => {
          setRecentSalesData(res.data.recentSales);
        })
        .catch((err) => {
          setError(err.response.data.message);
        })
    };
    getRecentSalesData();
  }, [filter,numberOfOrders]);




  return (
    <div className="col-12">
      <div className="card recent-sales overflow-auto">
        <div className="filter">
          <p className="icon" href="#" data-bs-toggle="dropdown" onClick={toggleDropdown}><i className="bi bi-three-dots"></i></p>
          <ul className={`dropdown-menu dropdown-menu-end dropdown-menu-arrow ${isOpen ? 'show' : ''}`}>
            <li className="dropdown-header text-start">
              <h6>Filter</h6>
            </li>
            <li><p className="dropdown-item" href="#" onClick={(e) => handleFilterClick(e, 5)}>last 5</p></li>
            <li><p className="dropdown-item" href="#" onClick={(e) => handleFilterClick(e, 10)}>last 10</p></li>
            <li><p className="dropdown-item" href="#" onClick={(e) => handleFilterClick(e, 20)}>last 20</p></li>
          </ul>
        </div>
        <div className="card-body">
          <h5 className="card-title">Recent Sales <span>| {filter}</span></h5>
          <table className="table table-borderless datatable">
            <thead>
              <tr>
                <th scope="col">Order No</th>
                <th scope="col">Customer</th>
                <th scope="col">Product</th>
                <th scope="col">Price</th>
                <th scope="col">Status</th>
              </tr>
            </thead>
            <tbody>
              {recentSalesData?.length > 0 && recentSalesData.map((item, index) => (
                <tr key={index}>
                  <th><p href="#">#{item.order_no}</p></th>
                  <td>{item.customer}</td>
                  <td>{item.product.name}</td>
                  <td>AED {item.product.price}</td>
                  <td><span className={`badge ${item.order_status === 'confirmed' ? 'bg-info' : item.order_status === 'shipped' ? 'bg-warning' : item.order_status === 'delivered' ? 'bg-success' : 'bg-danger'}`}>{item.order_status}</span></td>
                </tr>
              ))}
             
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default RecentSales;
