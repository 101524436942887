import { useState, useContext } from "react";
import axios from "axios";
import "../Styles/PasswordReset/PasswordReset.css";
import { FPContext } from "../Context/FPContext";
import { maskEmail } from "../Utils/EmailMask";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import Swal from "sweetalert2";

const PasswordReset = () => {
  const { email, OTPVerifiedRes, handleClosePopup,setDefault } = useContext(FPContext);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState({
    newPassword: false,
    confirmPassword: false,
  });
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }
    setIsSubmitting(true);
    setError("");
    // Add your password change logic here (e.g., API call)
    const response = await axios.post("/api/auth/reset-password", {
      email,
      password: newPassword,
    });
    setIsSubmitting(false);
    if (response.data.success) {
      handleClosePopup();
      setDefault();
      Swal.fire({
        icon: "success",
        title: response.data.message,
        showConfirmButton: false,
        timer: 1500,
      })
    } else {
      setError(response.data.message);
    }
  };

  const togglePasswordVisibility = (id) => {
    const passwordInput = document.getElementById(id);
    id === "new-password"
      ? setPasswordVisible({
          ...passwordVisible,
          newPassword: !passwordVisible.newPassword,
        })
      : setPasswordVisible({
          ...passwordVisible,
          confirmPassword: !passwordVisible.confirmPassword,
        });
    if (passwordInput.type === "password") {
      passwordInput.type = "text";
    } else {
      passwordInput.type = "password";
    }
  };

  return (
    <div className="password-change-container">
      {!error && OTPVerifiedRes && (
        <div className="verify-success">
          {OTPVerifiedRes}
          <CheckCircleIcon className="verify-success-icon" />
        </div>
      )}
      <h4>Create a New Password</h4>
      <p>
        Please create a new password for <strong>{maskEmail(email)}</strong>
      </p>
      <form onSubmit={handleSubmit} className="password-change-form">
        <div className="form-group">
          <label htmlFor="new-password">New Password</label>
          <input
            type="password"
            id="new-password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
          <span
            className="icon-eye"
            onClick={() => togglePasswordVisibility("new-password")}
          >
            {passwordVisible.newPassword ? <FaRegEyeSlash /> : <FaRegEye />}
          </span>
        </div>
        <div className="form-group">
          <label htmlFor="confirm-password">Confirm New Password</label>
          <input
            type="password"
            id="confirm-password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
          <span
            className="icon-eye"
            onClick={() => togglePasswordVisibility("confirm-password")}
          >
            {passwordVisible.confirmPassword ? <FaRegEyeSlash /> : <FaRegEye />}
          </span>
        </div>
        {error && <p className="error-message">{error}</p>}
        <button type="submit" className="password-submit-btn" disabled={isSubmitting}>
          {isSubmitting ? "Submitting..." : "Submit"}
        </button>
      </form>
    </div>
  );
};

export default PasswordReset;
