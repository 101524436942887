import React, { useEffect, useContext } from "react";
import { Formik, Field, ErrorMessage } from "formik";
import loginValidationSchema from "../Schemas/loginValidationSchema";
import axios from "axios";
import { useIntl } from "react-intl";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "../Styles/Login/Login.css";
import { toast } from "react-toastify";
import useUtilsStore from "../Store/utilsStore";
import { AdvancedImage, lazyload, placeholder } from "@cloudinary/react";
import { cloudinary } from "../Utils/CloudinarySetup";
import ForgotPassword from "../Components/ForgotPassword";
import { FPContext } from "../Context/FPContext";

export const Login = () => {
  const { showForgotPassword, handleForgotPasswordClick } =
    useContext(FPContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const location = useLocation();
  const navigate = useNavigate();
  const intl = useIntl();
  const { getLoggedInUser } = useUtilsStore();
  const initialValues = {
    email: "",
    password: "",
  };

  const handleSubmit = (values) => {
    axios
      .post(`/api/users/login`, values)
      .then(async (res) => {
        if (res.data.success) {
          localStorage.setItem(
            "alambraAccessToken",
            res.headers.get("access-token")
          );
          localStorage.setItem(
            "alambraRefreshToken",
            res.headers.get("refresh-token")
          );
          await getLoggedInUser();
          toast.success(res.data.message);
          const localCart = JSON.parse(localStorage.getItem("alambraCart"));
          if (localCart) {
            axios
              .post(
                "/api/cart/localToCart",
                { products: localCart },
                {
                  headers: {
                    Authorization: `access-token ${localStorage.getItem(
                      "alambraAccessToken"
                    )}`,
                    "refresh-token": localStorage.getItem(
                      "alambraRefreshToken"
                    ),
                  },
                }
              )
              .then((res) => {
                if (res.data.success) {
                  localStorage.removeItem("alambraCart");
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (location.state && location.state.from) {
            navigate(location.state.from);
          } else {
            navigate("/");
          }
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const handleGoogleLogin = () => {
    let url = process.env.REACT_APP_GOOGLE_LOGIN_URL;
    if (location.state && location.state.from) {
      url += `?from=${location.state.from}`;
    }
    window.location.href = url;
  };

  return (
    <div className="login">
      <div className="mobile-screen">
        <div className="background-container">
          <div className="banner-section">
            <AdvancedImage
              cldImg={cloudinary
                .image("alambra stock images/rdbqy0fyqgfg0t16qo0g")
                .format("auto")
                .quality("auto")}
              plugins={[lazyload(), placeholder({ mode: "blur" })]}
            />
          </div>
          <div className="login-section">
            <div className="classic-login">
              <div className="login-headerText">
              <h5>{intl.formatMessage({ id: "login.title" })}</h5>
               <Link to="/signup">
                {intl.formatMessage({ id: "login.signup" })}
              </Link>
            </div>
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={loginValidationSchema}
            >
              {({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <div className="login-form-container">
                    <div className="email-input-area">
                      <Field
                        placeholder={intl.formatMessage({
                          id: "login.placeholder.email",
                        })}
                        type="email"
                        name="email"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="error error-email"
                      />
                    </div>
                    <div className="password-input-area">
                      <Field
                        placeholder={intl.formatMessage({
                          id: "login.placeholder.password",
                        })}
                        type="password"
                        name="password"
                      />
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="error error-password"
                      />
                    </div>

                    <button type="submit">
                      {intl.formatMessage({ id: "login.button" })}
                    </button>
                  </div>
                </form>
              )}
            </Formik>
            <p
              className="forgot-password"
              style={{ cursor: "pointer", textAlign: "center" }}
              onClick={handleForgotPasswordClick}
            >
              {intl.formatMessage({ id: "login.forgot" })}
            </p></div>
            
            <hr />
            <div className="social-login-section">
              <p>{intl.formatMessage({ id: "login.option" })}</p>
              <div className="social-login">
                <img
                  onClick={handleGoogleLogin}
                  src="../../assets/images/social_media/google.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {showForgotPassword && <ForgotPassword />}
    </div>
  );
};
