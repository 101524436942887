import React from "react";
import SalesCard from "../Components/SalesCard";
import RevenueCard from "../Components/RevenueCard";
import CustomersCard from "../Components/CustomersCard";
import ReportsCard from "../Components/ReportsCard";
import RecentSales from "../Components/RecentSalesCard";
import TopSelling from "../Components/TopSellingCard";
import RecentActivityCard from "../Components/RecentActivityCard";
import SalesByCategoryCard from "../Components/SalesByCategoryCard";

const AdminDashboard = () => {
  return (
    <section className="section dashboard">
      <div className="row">
        {/* Left side columns */}
        <div className="col-lg-8">
          <div className="row">
            
            <SalesCard />
            

            
            <RevenueCard />
           

            
            <CustomersCard />
           

            
            <ReportsCard />
           

            
            <RecentSales />
           
           
            <TopSelling />
          
          </div>
        </div>
        {/* End Left side columns */}



         {/* Right side columns */}
         <div className="col-lg-4">
          <RecentActivityCard />
        
         

          <SalesByCategoryCard />
        </div>
        {/* End Right side columns */}
      </div>
    </section>
  );
};

export default AdminDashboard;
