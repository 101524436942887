// ProductCard.js

import React from 'react';
import '../Styles/ProductSection/ProductSection.css';

const ProductCard = ({ item, isRtl, handleNavigate, Server }) => {
    
  return (
    <div
      className={`productCard ${item?.out_of_stock ? 'outOfStock' : ''}`}
      onClick={() => {
        if (!item?.out_of_stock) {
          handleNavigate(item._id);
        }
      }}
    >
      {item?.out_of_stock && <div className="outOfStockOverlay">Out of Stock</div>}
      <div className="productImageCard">
        <img
          className="productImage"
          src={`${Server}${item?.rawImages[0]?.path}`}
          alt=""
        />
      </div>
      <span className="productItemTitle">
        {isRtl ? item.name_ar : item.name_en.toUpperCase()}
      </span>
      <div className="productItemPriceContainer">
        <span className="productItemPrice">
          AED {item.price}
        </span>
      </div>
    </div>
  );
};

export default ProductCard;
