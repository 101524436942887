import React, { useEffect, useMemo } from "react";
import useAdminStore from "../Store/adminStore";
import { Box, Button, MenuItem, Select, Typography } from "@mui/material";
import { BeatLoader } from "react-spinners";
import Swal from "sweetalert2";
import axios from "axios";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import InvoiceGen from "../Components/Invoice";
//Date Picker Imports - these should just be in your Context Provider
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

const AllOrdersTable = () => {
  const Server = process.env.REACT_APP_SERVER;
  const { allOrders, getAllOrders, allOrdersLoading } = useAdminStore();
  useEffect(() => {
    getAllOrders();
  }, [getAllOrders]);

  const handleOrderStatus = (value, orderId, subOrderId, paymentMethod) => {
    Swal.fire({
      title: `Change order status to ${value}?`,
      text: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Change",
    }).then((result) => {
      if (result.isConfirmed) {
        const body = {
          subOrderId,
          order_status: value,
          cancelled: false,
          cancelled_by: null,
          cancelled_date: null,
        };
        if (value === "shipped") {
          body.shipped_date = Date.now();
        }
        if (value === "out for delivery") {
          body.out_for_delivery_date = Date.now();
          body.delivery_status = "out for delivery";
        }
        if (value === "delivered") {
          body.delivery_date = Date.now();
          body.delivery_status = "delivered";
          paymentMethod === "cashOnDelivery" && (body.payment_status = "paid");
        }
        if (value === "cancelled") {
          body.order_status = "cancelled";
          body.cancelled = true;
          body.cancelled_date = Date.now();
          body.cancelled_by = "admin";
        }
        if (value === "confirmed") {
          paymentMethod === "cashOnDelivery" &&
            (body.payment_status = "pending");
        }
        axios
          .patch(`/api/admin/orders/update/${orderId}`, body, {
            headers: {
              Authorization: `admin-access-token ${localStorage.getItem(
                "alambraAdminAccessToken"
              )}`,
            },
          })
          .then((res) => {
            if (res.data.success) {
              Swal.fire("Updated!", res.data.message, "success");

              window.location.reload();
            }
          })
          .catch((err) => {
            Swal.fire("Error!", err.response.data.message, "error");
          });
      }
    });
  };

  const generateInvoice = async (order) => {
    try {
      await InvoiceGen(order);
    } catch (error) {
      console.log(error);
    }
  };

  const columns = useMemo(
    () => [
      {
        id: "order_no",
        header: "Order ID",
        accessorKey: "order_no",
      },
      {
        id: "product",
        header: "Product",
        accessorKey: "product_name",
      },
      {
        id: "price",
        header: "Price",
        accessorKey: "price",
      },
      {
        id: "quantity",
        header: "Qty",
        accessorKey: "quantity",
      },
      {
        id: "user",
        header: "User",
        accessorKey: "user",
      },
      {
        id: "order_date",
        header: "Order Date",
        accessorFn: (row) =>
          new Date(row?.order_date).toLocaleDateString("en-AE", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          }),
      },
      {
        id: "payment",
        header: "Payment",
        accessorKey: "payment_status",
      },
      {
        id: "order_status",
        header: "Order Status",
        accessorKey: "order_status",
        Cell: ({ cell }) => (
          <Select
            value={cell.getValue()}
            onChange={(e) =>
              handleOrderStatus(
                e.target.value,
                cell.row.original._id,
                cell.row.original.subOrderId,
                cell.row.original.payment_method
              )
            }
          >
            <MenuItem value="confirmed">Confirmed</MenuItem>
            <MenuItem value="shipped">Shipped</MenuItem>
            <MenuItem value="out for delivery">Out for Delivery</MenuItem>
            <MenuItem value="delivered">Delivered</MenuItem>
            <MenuItem value="cancelled">Cancelled</MenuItem>
          </Select>
        ),
      },
      {
        id: "action",
        header: "Action",
        Cell: ({ row }) => (
          <Button
            onClick={() => generateInvoice(row.original.order)}
            variant="outlined"
          >
            Invoice
          </Button>
        ),
      },
    ],
    []
  );

  // Map each product within an order to a separate row
  const mappedData = useMemo(() => {
    const rows = [];
    allOrders?.forEach((order) => {
      order.product.forEach((product) => {
        rows.push({
          order: order,
          order_no: order?.order_no,
          product_name:
            product?.productDetails?.name_en +
            " " +
            product?.productDetails?.category_en +
            " " +
            product?.productDetails?.quantity,
          price: "AED " + product?.price,
          quantity: product?.quantity,
          user: order?.user + " | " + order?.userEmail,
          order_date: product?.order_date,
          payment_status:
            product?.payment_method + " " + product?.payment_status,
          order_status: product?.order_status,
          avatar: product?.productDetails?.rawImages[0]?.path,
          shipping_address: product?.shipping_address,
          phone: product?.phone,
          _id: order?._id,
          subOrderId: product?._id,
          payment_method: product?.payment_method,
        });
      });
    });
    return rows;
  }, [allOrders]);

  const table = useMaterialReactTable({
    columns,
    data: mappedData || [],
    enableColumnFilterModes: true,
    enableGrouping: true,
    enableColumnPinning: true,
    enableFacetedValues: true,
    initialState: {
      showColumnFilters: false,
      showGlobalFilter: true,
      columnPinning: {
        left: ["mrt-row-expand", "mrt-row-select"],
        right: ["mrt-row-actions"],
      },
    },
    paginationDisplayMode: "pages",
    positionToolbarAlertBanner: "bottom",
    muiSearchTextFieldProps: {
      size: "small",
      variant: "outlined",
    },
    muiPaginationProps: {
      color: "secondary",
      rowsPerPageOptions: [10, 20, 30],
      shape: "rounded",
      variant: "outlined",
    },
    renderDetailPanel: ({ row }) => (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          left: "30px",
          maxWidth: "1000px",
          position: "sticky",
          width: "100%",
        }}
      >
        <img
          alt="avatar"
          height={200}
          src={`${Server}${row.original.avatar}`}
          loading="lazy"
          style={{ borderRadius: "50%" }}
        />
        <Box sx={{ textAlign: "center" }}>
          <Typography sx={{ fontWeight: "bold" }} variant="h6">
            Shipping Address:
          </Typography>
          <Typography variant="body1">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="body1">
                {row.original.shipping_address.name + " , "}
              </Typography>
              <Typography variant="body1">
                {row.original.shipping_address.addressLine1 + " , "}
              </Typography>
              {row.original.shipping_address.addressLine2 && (
                <Typography variant="body1">
                  {row.original.shipping_address.addressLine2 + " , "}
                </Typography>
              )}
              <Box sx={{ display: "flex" }}>
                {row.original.shipping_address.city && (
                  <Typography variant="body1">
                    {row.original.shipping_address.city + " , "}
                  </Typography>
                )}
                {row.original.shipping_address.state && (
                  <Typography variant="body1">
                    {row.original.shipping_address.state + " , "}
                  </Typography>
                )}
                {row.original.shipping_address.postalCode && (
                  <Typography variant="body1">
                    {row.original.shipping_address.postalCode + " , "}
                  </Typography>
                )}
                {row.original.shipping_address.country && (
                  <Typography variant="body1">
                    {row.original.shipping_address.country}
                  </Typography>
                )}
              </Box>
            </Box>
          </Typography>
        </Box>
        <Box sx={{ textAlign: "center" }}>
          <Typography sx={{ fontWeight: "bold" }} variant="h6">
            Phone:
          </Typography>
          {row.original.phone && (
            <Typography variant="body1">{row.original.phone}</Typography>
          )}
        </Box>
      </Box>
    ),
  });

  return (
    <Box style={{ padding: "5vw" }}>
      {allOrdersLoading ? (
        <div
          className="loading"
          style={{
            height: "50vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <BeatLoader color="#3498db" size={"1.5vw"} />
        </div>
      ) : (
        <>
          <Box >
            <Typography
              sx={{ fontWeight: "bold", margin: 0, marginBottom: "10px" }}
              variant="h5"
            >
              All Orders
            </Typography>
            <MaterialReactTable table={table} />
          </Box>
        </>
      )}
    </Box>
  );
};

const AdminAllOrders = () => (
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <AllOrdersTable />
  </LocalizationProvider>
);

export default AdminAllOrders;
