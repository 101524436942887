import { createContext, useState } from "react";

export const FPContext = createContext();

export const FPProvider = ({ children }) => {
  const [OTPSent, setOTPSent] = useState(false);
  const [OTPVerified, setOTPVerified] = useState(false);
  const [email, setEmail] = useState("");
  const [OTPSentRes, setOTPSentRes] = useState("");
  const [OTPVerifiedRes, setOTPVerifiedRes] = useState("");
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const setDefault = () => {
    setEmail("");
    setOTPSent(false);
    setOTPSentRes("");
    setOTPVerified(false);
    setOTPVerifiedRes("");
  };
  const handleForgotPasswordClick = () => {
    setShowForgotPassword(true);
  };
  const handleClosePopup = () => {
    setShowForgotPassword(false);
  };
  return (
    <FPContext.Provider
      value={{
        OTPSentRes,
        OTPVerifiedRes,
        OTPSent,
        OTPVerified,
        email,
        setOTPSent,
        setOTPVerified,
        setEmail,
        setOTPSentRes,
        setOTPVerifiedRes,
        setDefault,
        showForgotPassword,
        handleForgotPasswordClick,
        handleClosePopup,
      }}
    >
      {children}
    </FPContext.Provider>
  );
};
