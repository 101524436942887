import React, { useEffect, useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
const SalesCard = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState('Today');
  const [salesData, setSalesData] = useState();
  // eslint-disable-next-line
  const [error, setError] = useState();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleFilterClick = (newFilter) => {
    setFilter(newFilter);
    setIsOpen(false);
  };

 
  useEffect(() => {
    const getSalesData = () => {
      axios.get(`/api/admin/dashboardData/salesCount/${filter}`, {
        headers: {
          Authorization: `admin-access-token ${localStorage.getItem("alambraAdminAccessToken")}`
        }
      })
        .then((res) => {
          setSalesData(res.data);
        })
        .catch((err) => {
          setError(err.response.data.message);
        })
    };
  
    getSalesData();
  }, [filter]);

  return (
    <div className="col-xxl-4 col-md-6">
      <div className="card info-card sales-card">
        <div className="filter">
          <p className="icon" href="#" data-bs-toggle="dropdown" onClick={toggleDropdown}><i className="bi bi-three-dots"></i></p>
          <ul className={`dropdown-menu dropdown-menu-end dropdown-menu-arrow ${isOpen ? 'show' : ''}`}>
            <li className="dropdown-header text-start">
              <h6>Filter</h6>
            </li>
            <li><p className="dropdown-item"  onClick={() => handleFilterClick('Today')} >Today</p></li>
            <li><p className="dropdown-item"  onClick={() => handleFilterClick('This Month')}>This Month</p></li>
            <li><p className="dropdown-item"  onClick={() => handleFilterClick('This Year')}>This Year</p></li>
          </ul>
        </div>
        <div className="card-body">
          <h5 className="card-title">Sales <span>| {filter}</span></h5>
          <div className="d-flex align-items-center">
            <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
              <i className="bi bi-cart"></i>
            </div>
            <div className="ps-3">
              <h6>{salesData?.TotalSales}</h6>
              <span className={`${salesData?.SalesProgress > 0 ? 'text-success' : 'text-danger'} small pt-1 fw-bold`}>{salesData?.SalesProgress.toFixed(2)}%</span>
              <span className="text-muted small pt-2 ps-1">{salesData?.SalesProgress > 0 ? 'increase' : 'decrease'}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesCard;
