
import React,{useEffect} from 'react';
import { AdvancedImage } from "@cloudinary/react";
import { useNavigate, useParams } from 'react-router-dom';
import useUtilsStore from '../Store/utilsStore';
import { cloudinary } from '../Utils/CloudinarySetup';
import ProductCard from './ProductCard';
import useProductStore from '../Store/productStore';
import '../Styles/ProductSection/ProductSection.css';

const ProductListSection = () => {
    const { id } = useParams();
    const {isRtl} = useUtilsStore();
    const navigate = useNavigate();
    const Server = process.env.REACT_APP_SERVER;
    const {relatedProducts,getRelatedProducts} = useProductStore();
    const handleNavigate = (id) => {
        navigate(`/product/${id}`);
        window.scrollTo(0, 0);
    }
    useEffect(() => {
        getRelatedProducts(id);
    }, [id,getRelatedProducts])

  return (
    <div className="productSection">
      <div className="productContainer">
        <div className="logo2Container">
          <AdvancedImage
            className="logo2"
            cldImg={cloudinary
              .image("alambra stock images/ojicfhmdw0lg3vibxaqs")
              .format("auto")
              .quality("auto")}
            alt=""
          />
        </div>
        <span className="products">
          RELATED PRODUCTS
        </span>
      </div>
      <div className="productCardContainer">
        {relatedProducts?.map((item, index) => (
          <ProductCard
            key={index}
            item={item}
            isRtl={isRtl}
            handleNavigate={handleNavigate}
            Server={Server}
          />
        ))}
      </div>
    </div>
  );
};

export default ProductListSection;
