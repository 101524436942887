import {create} from 'zustand';
import axios from 'axios';

const useAdminStore = create((set) => ({
 allProducts: null,
 allProductsLoading: false,
 allProductsError: null,
 getAllProducts: async () => {
   set({ allProductsLoading: true });
   try {
     const res = await axios.get(`/api/admin/products`, {
       headers: {
         Authorization: `admin-access-token ${localStorage.getItem("alambraAdminAccessToken")}`,
       },
     });
     set({ allProducts: res.data.products, allProductsLoading: false });
   } catch (error) {
     set({ allProductsError: error, allProductsLoading: false });
   }
 },

 productById: null,
 productByIdLoading: false,
 productByIdError: null,
 getProductById: async (id) => {
   set({ productByIdLoading: true });
   try {
     const res = await axios.get(`/api/admin/products/product/${id}`, {
       headers: {
         Authorization: `admin-access-token ${localStorage.getItem("alambraAdminAccessToken")}`,
       },
     });
     set({ productById: res.data.product, productByIdLoading: false });
   } catch (error) {
     set({ productByIdError: error, productByIdLoading: false });
   }
 },

 allOrders:null,
 allOrdersLoading:false,
 allOrdersError:null,
 getAllOrders:async()=>{
   set({allOrdersLoading:true})
   try{
     const res =await axios.get(`/api/admin/orders`,{
       headers:{
         Authorization:`admin-access-token ${localStorage.getItem("alambraAdminAccessToken")}`
       }
     })
     set({allOrders:res.data.orders,allOrdersLoading:false})
   }catch(error){
     set({allOrdersError:error,allOrdersLoading:false})
   }
 },

 loggedInAdmin:null,
 loggedInAdminLoading:false,
 loggedInAdminError:null,
 getLoggedInAdmin:async()=>{
   set({loggedInAdminLoading:true})
   try{
     const res=await axios.get(`/api/admin/auth/loggedinAdmin`,{
       headers:{
         Authorization:`admin-access-token ${localStorage.getItem("alambraAdminAccessToken")}`
       }
     })
     set({loggedInAdmin:res.data.admin,loggedInAdminLoading:false})
   }catch(error){
     set({loggedInAdmin:null,loggedInAdminError:error,loggedInAdminLoading:false})
   }
 },
 bannerImages:null,
 bannerImagesLoading:false,
 bannerImagesError:null,
 getBannerImages:async()=>{
   set({bannerImagesLoading:true})
   try{
     const res= await axios.get(`/api/admin/banner/bannerImages`);
     set({bannerImages:res.data.bannerImages?.[0],bannerImagesLoading:false})
   }catch(error){
     set({bannerImages:null,bannerImagesError:error,bannerImagesLoading:false})
   }
 }
}))

export default useAdminStore;