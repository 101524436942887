import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import AdminLogin from './Pages/AdminLogin';
import AdminDashboard from './Pages/AdminDashboard';
import AdminAllProductsPage from './Pages/AdminAllProductsPage';
import AddProduct from './Pages/AdminAddProduct';
import EditProduct from './Pages/AdminEditProduct';
import AdminNavbar from './Components/AdminNavbar';
import AdminAddAdmin from './Pages/AdminAddAdmin';
import AdminPrivateRoutes from './Utils/AdminPrivateRoutes';
import AdminNotFound from './Pages/AdminNotFound';
import AdminForgotPassword from './Pages/AdminForgotPassword';
import AdminResetPassword from './Pages/AdminResetPassword';
import AdminBannerImages from './Pages/AdminBannerImages';
import AdminAllOrders from './Pages/AdminAllOrdersPage';


const AdminApp = () => {
  return (
    <div className="AdminApp">
        <BrowserRouter>
        <AdminNavbar />
        <Routes>
          <Route path="/admin/login" element={<AdminLogin />} />
          <Route path="/admin/forgot-password" element={<AdminForgotPassword />} />
          <Route path="/admin/reset-password/:token" element={<AdminResetPassword />} />
          <Route element={<AdminPrivateRoutes />}>
          <Route path='/admin' element={<Navigate to="/admin/dashboard" />} />
          <Route path="/admin/dashboard" element={<AdminDashboard />}/>
          <Route path="/admin/all-products" element={<AdminAllProductsPage />} />
          <Route path='/admin/add-product' element={<AddProduct/>}/>
          <Route path='/admin/edit-product/:id' element={<EditProduct/>} />
          <Route path='/admin/add-admin' element={<AdminAddAdmin/>} />
          <Route path='/admin/banner-images' element={<AdminBannerImages/>}/>
          <Route path='/admin/all-orders' element={<AdminAllOrders/>}/>  
          </Route>
          

          <Route path="*" element={<AdminNotFound />} />
          
        </Routes>
        </BrowserRouter>
    </div>
  )
}

export default AdminApp