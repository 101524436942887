import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import jwt from "jwt-decode";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import "../Styles/AdminResetPassword/AdminResetPassword.css";
import Swal from "sweetalert2";

const AdminResetPassword = () => {
    const { token } = useParams();
  const [decodedToken, setDecodedToken] = useState(null);
  const [error, setError] = useState(null);

    useEffect(() => {
      try {
        const decoded = jwt(token);

        const currentTimestamp = Math.floor(Date.now() / 1000); // Current time in seconds
        if (decoded.exp && decoded.exp < currentTimestamp) {
          setError("Reset password link expired!");
        } else {
          setDecodedToken(decoded);
        }
      } catch (error) {
        setError("Invalid Reset password link!");
      }
    }, [token]);

    console.log(decodedToken);

  const resetPasswordFormValiationSchema = Yup.object().shape({
    email: Yup.string().email("*Invalid email").required("*Email is required"),
    password: Yup.string()
      .min(8, "*Password must be at least 8 characters long")
      .required("*Password is Required"),
    repeatPassword: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "*Passwords must match"
    ),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: decodedToken?.email,
      password: "",
      repeatPassword: "",
    },
    validationSchema: resetPasswordFormValiationSchema,
    onSubmit: async (values, { setSubmitting }) => {
        try {
            setSubmitting(true);
            const response = await axios.post("/api/admin/auth/reset-password", values,{
                headers: {
                    Authorization: `admin-reset-password-token ${token}`,
                },
            });
            if(response.data.success){
                Swal.fire({
                    icon: "success",
                    title: response.data.message,
                }).then((result) => {
                    result.isConfirmed && formik.resetForm();
                })
            }
            
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: error.response.data.message,
            }).then((result) => {
                result.isConfirmed && formik.resetForm();
            })
        } finally {
            setSubmitting(false);
        }
    },
  });

  return (
    <div className="adminResetPassword">
      {error ? (
        <div className="token-status-error">
          <h4>{error}</h4>
        </div>
      ) : decodedToken && (
        <div>
          <h3 className="title">Reset Password</h3>
          <form onSubmit={formik.handleSubmit}>
            <div className="form-group">
              <div className="grouping-with-errorbox">
                <label htmlFor="email">Email</label>
                <input
                  name="email"
                  placeholder="Email*"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  className="email"
                  id="email"
                  type="email"
                  disabled
                />
                {formik.touched.email && formik.errors.email && (
                  <div className="error-msg">{formik.errors.email}</div>
                )}
              </div>
              <div className="grouping-with-errorbox">
                <label htmlFor="password">Enter your password</label>
                <input
                  name="password"
                  placeholder="password*"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  className="password"
                  id="password"
                  type="password"
                />
                {formik.touched.password && formik.errors.password && (
                  <div className="error-msg">{formik.errors.password}</div>
                )}
              </div>
              <div className="grouping-with-errorbox">
                <label htmlFor="repeatPassword">Repeat your Password</label>
                <input
                  name="repeatPassword"
                  placeholder="Repeat Password*"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.repeatPassword}
                  className="repeatPassword"
                  id="repeatPassword"
                  type="repeatPassword"
                />
                {formik.touched.repeatPassword &&
                  formik.errors.repeatPassword && (
                    <div className="error-msg">
                      {formik.errors.repeatPassword}
                    </div>
                  )}
              </div>
              <button
                className="submit-button"
                disabled={formik.isSubmitting}
                type="submit"
              >
                {formik.isSubmitting ? "Processing..." : "Submit"}
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default AdminResetPassword;
