import { create } from "zustand";
import jwt_decode from "jwt-decode";
import axios from "axios";

const useUtilsStore = create((set, get) => ({
  isRtl: false,
  setRtl: (bool) => {
    set({ isRtl: bool });
  },
  loggedInUser: null,
  loggedInUserLoading: false,
  loggedInUserError: null,
  getLoggedInUser: () => {
    set({ loggedInUserLoading: true });
    try {
      const token = localStorage.getItem("alambraAccessToken");
      if (token) {
        const decoded = jwt_decode(token);
        if (decoded) {
          axios.get(`/api/users/${decoded.userId}`).then((res) => {
            set({ loggedInUser: res.data?.user, loggedInUserLoading: false });
          });
        }
      } else {
        set({ loggedInUser: null, loggedInUserLoading: false });
      }
    } catch (error) {
      set({
        loggedInUser: null,
        loggedInUserError: error,
        loggedInUserLoading: false,
      });
    }
  },
  userCartCount: null,
  userCartCountLoading: false,
  userCartCountError: null,
  getUserCartCount: async () => {
    try {
      axios
        .get("/api/cart/userCartCount", {
          headers: {
            Authorization: `access-token ${localStorage.getItem(
              "alambraAccessToken"
            )}`,
          },
        })
        .then((res) => {
          if (res.data.success) {
            set({
              userCartCount: res.data.cartCount,
              userCartCountLoading: false,
            });
          }
        })
        .catch((error) => {
          set({
            userCartCount: null,
            userCartCountError: error,
            userCartCountLoading: false,
          });
        });
    } catch (error) {
      set({
        userCartCount: null,
        userCartCountError: error,
        userCartCountLoading: false,
      });
    }
  },

  localCartCount: null,
  localCartCountLoading: false,
  localCartCountError: null,
  getLocalCartCount: async () => {
    try {
      const localCart = await JSON.parse(localStorage.getItem("alambraCart"));
      if (localCart) {
        set({
          localCartCount: localCart.length,
          localCartCountLoading: false,
          localCartCountError: null,
        });
      }else{
        set({
          localCartCount: null,
          localCartCountLoading: false,
          localCartCountError: null,
        });
      }

    } catch (error) {
      set({
        localCartCount: null,
        localCartCountError: error,
        localCartCountLoading: false,
      });
    }
  },

  userIpAndCountry: null,
  userIpAndCountryLoading: false,
  userIpAndCountryError: null,
  getUserIpAndCountry: () => {
    set({ userIpAndCountryLoading: true });
    try {
      axios.get("http://ip-api.com/json").then((res) => {
        set({ userIpAndCountry: res.data, userIpAndCountryLoading: false });
      });
    } catch (error) {
      set({
        userIpAndCountry: null,
        userIpAndCountryError: error,
        userIpAndCountryLoading: false,
      });
    }
  },
}));

export default useUtilsStore;
