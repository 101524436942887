import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import useAdminStore from "../Store/adminStore";
import { TagsInput } from "react-tag-input-component";
import AdminProductsValidationSchema from "../Schemas/AdminProductsSchema";
import { TiDelete } from "react-icons/ti";
import "../Styles/ProductControl/ProductControl.css";
import axios from "axios";
import Swal from "sweetalert2";

const EditProduct = () => {
  const { id } = useParams();
  const fileInputRef = useRef(null);
  const { productById, getProductById } = useAdminStore();
  const Server = process.env.REACT_APP_SERVER;
  const navigate = useNavigate();

  useEffect(() => {
    getProductById(id);
  }, [id, getProductById]);

  useEffect(() => {
    if (productById) {
      if (productById?.rawImages) {
        const images = productById?.rawImages
          ?.map((image) => {
            return {
              ...image,
              type: "path",
              order: image.index,
            };
          })
          .sort((a, b) => a.index - b.index);
        setImageThumbs(images);
      }
    }
  }, [productById]);

  const [imageThumbs, setImageThumbs] = useState([]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name_en: productById?.name_en,
      name_ar: productById?.name_ar,
      description_en: productById?.description_en ?? "",
      description_ar: productById?.description_ar ?? "",
      category_en: productById?.category_en ?? "",
      category_ar: productById?.category_ar ?? "",
      qty: Number(productById?.quantity?.slice(0, -2)),
      unit: productById?.quantity?.slice(-2),
      price: productById?.price,
      topNote: productById?.notes?.top ?? [],
      heartNote: productById?.notes?.heart ?? [],
      baseNote: productById?.notes?.base ?? [],
      imageFiles: [],
      listed_in: productById?.listed_in,
    },

    validationSchema: AdminProductsValidationSchema,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(false);

      values.category_ar =
        values.category_en === "perfume"
          ? "عطر"
          : values.category_en === "oil"
          ? "زيت"
          : values?.category_en === "Faraash"
          ? "فراش"
          : values.category_en === "Hair Mist"
          ? "بخاخ الشعر"
          : values.category_en === "Body Mist"
          ? "بخاخ الجسم"
          : values.category_en === "Bakhoor"
          ? "بخور"
          : "";

      const changedValues = {};
      Object.keys(values).forEach((key) => {
        if (
          JSON.stringify(values[key]) !==
          JSON.stringify(formik.initialValues[key])
        ) {
          changedValues[key] = values[key];
          if (changedValues.hasOwnProperty("qty")) {
            changedValues["unit"] = values["unit"];
          }
          if (changedValues.hasOwnProperty("unit")) {
            changedValues["qty"] = values["qty"];
          }
          if (changedValues.hasOwnProperty("imageFiles")) {
            changedValues["qtty"] = values["qty"] + values["unit"];
            changedValues["category"] = values["category_en"];
          }
        }
      });

      if (
        formik.values !== formik.initialValues &&
        Object.keys(changedValues).length > 0
      ) {
        setSubmitting(true);
        const reversed = Object.fromEntries(
          Object.entries(changedValues).reverse()
        );
        const formData = new FormData();
        for (const key in reversed) {
          if (reversed.hasOwnProperty(key)) {
            const value = reversed[key];
            if (Array.isArray(value)) {
              if (
                (key === "topNote" ||
                  key === "heartNote" ||
                  key === "baseNote") &&
                value.length === 0
              ) {
                formData.append(key, []);
              } else {
                // Handle other arrays by appending their items
                for (const item of value) {
                  formData.append(key, item);
                }
              }
            } else {
              // Append a non-array field
              formData.append(key, value);
            }
          }
        }

        axios
          .patch(`/api/admin/products/update/${id}`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `admin-access-token ${localStorage.getItem(
                "alambraAdminAccessToken"
              )}`,
            },
          })
          .then((res) => {
            setSubmitting(false);
            if (res.data.success) {
              Swal.fire({
                icon: "success",
                title: res.data.message,
              }).then((result) => {
                if (result.isConfirmed) {
                  navigate("/admin/all-products");
                }
              });
            }
          })
          .catch((error) => {
            setSubmitting(false);
            Swal.fire({
              icon: "error",
              title: error?.response ? error.response.data.message : error?.message,
            });
          });
      } else {
        Swal.fire({
          icon: "info",
          title: "Nothing changed",
          text: "Please make some changes before submitting",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    },
  });

  const deleteImage = (image, index) => {
    const updatingImageThumbs = [...imageThumbs];
    updatingImageThumbs.splice(index, 1);
    const updateImagesOrder = updatingImageThumbs.map((img, i) => ({
      ...img,
      order: i,
    }));
    setImageThumbs(updateImagesOrder);
    if (image.type === "path") {
      formik.setFieldValue("deleteExisting", true);
      formik.values.deletingImages
        ? formik.values.deletingImages.push(image.path)
        : formik.setFieldValue("deletingImages", [image.path]);
    }
    const existingImagesOrder = updateImagesOrder.map((imge) => {
      return {
        path: imge.path,
        order: imge.order,
      };
    });
    formik.setFieldValue("exImageOrder", JSON.stringify(existingImagesOrder));
    const newImages = updateImagesOrder.filter((img) => img.type === "image");
    const newImageFiles = newImages.map((image) => {
      return image.image;
    });
    const updatedNewImagesOrder = newImages?.map((image) => {
      return {
        filename: image.image.name,
        order: image.order,
      };
    });
    formik.setFieldValue("imageFiles", newImageFiles);
    formik.setFieldValue(
      "newImagesOrder",
      JSON.stringify(updatedNewImagesOrder)
    );
  };

  const [draggedIndex, setDraggedIndex] = useState(null);

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData("imageIndex", index.toString());
    setDraggedIndex(index);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, dropIndex) => {
    if (draggedIndex === null) return;
    const Images = [...imageThumbs];
    const [draggedItem] = Images.splice(draggedIndex, 1);
    Images.splice(dropIndex, 0, draggedItem);
    const updatedImages = Images.map((image, index) => {
      return {
        ...image,
        order: index,
      };
    });
    setImageThumbs(updatedImages);
    const existingImagesOrder = updatedImages
      .filter((img) => img.type === "path")
      .map((imge) => {
        return {
          path: imge.path,
          order: imge.order,
        };
      });
    const newImages = updatedImages.filter((img) => img.type === "image");
    console.log("newImages", newImages);
    const newImagesOrder = newImages.map((image) => {
      return {
        filename: image.image.name,
        order: image.order,
      };
    });
    const newImageFiles = newImages.map((image) => {
      return image.image;
    });
    formik.setFieldValue("imageFiles", newImageFiles);
    formik.setFieldValue("exImageOrder", JSON.stringify(existingImagesOrder));
    formik.setFieldValue("newImagesOrder", JSON.stringify(newImagesOrder));
    setDraggedIndex(null);
  };

  const handleImageChange = (event) => {
    const newImages = Array.from(event.currentTarget.files);
    const newImagesObj = newImages.map((image, index) => {
      return {
        type: "image",
        image,
        order: imageThumbs.length + index,
      };
    });
    const newImagesOrder = newImages.map((image, index) => {
      return {
        filename: image.name,
        order: imageThumbs.length + index,
      };
    });
    setImageThumbs([...imageThumbs, ...newImagesObj]);
    formik.setFieldValue("imageFiles", [
      ...formik.values.imageFiles,
      ...newImages,
    ]);

    //check for formik.values.newImagesOrder
    const parsedNewImagesOrder = formik.values.newImagesOrder
      ? JSON.parse(formik.values.newImagesOrder)
      : [];
    formik.setFieldValue(
      "newImagesOrder",
      JSON.stringify([...parsedNewImagesOrder, ...newImagesOrder])
    );
  };

  return (
    <>
    <h4 style={{ textAlign: "center", marginTop: "2vw" }}>Edit Product</h4>
    <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
      <div className="product-control-container">
        <div className="grouping-with-errorbox">
          <label htmlFor="name_en">Name in English*</label>
          <input
            name="name_en"
            placeholder="Name in English*"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name_en}
            className="name-english"
            type="text"
          />
          {formik.touched.name_en && formik.errors.name_en && (
            <div className="error-msg">{formik.errors.name_en}</div>
          )}
        </div>
        <div className="grouping-with-errorbox">
          <label htmlFor="name_ar">Name in Arabic*</label>
          <input
            name="name_ar"
            placeholder="Name in Arabic*"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name_ar}
            className="name-arabic"
            type="text"
          />
          {formik.touched.name_ar && formik.errors.name_ar && (
            <div className="error-msg">{formik.errors.name_ar}</div>
          )}
        </div>
        <div className="grouping-with-errorbox">
          <label htmlFor="description_en">Description in English</label>
          <textarea
            name="description_en"
            placeholder="Description in English"
            cols="21"
            rows="5"
            value={formik.values.description_en}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="description-english"
          />
          {formik.touched.description_en && formik.errors.description_en && (
            <div className="error-msg">{formik.errors.description_en}</div>
          )}
        </div>
        <div className="grouping-with-errorbox">
          <label htmlFor="description_ar">Description in Arabic</label>
          <textarea
            name="description_ar"
            placeholder="Description in Arabic"
            cols="21"
            rows="5"
            value={formik.values.description_ar}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="description-english"
          />
          {formik.touched.description_ar && formik.errors.description_ar && (
            <div className="error-msg">{formik.errors.description_ar}</div>
          )}
        </div>
        <div className="grouping-with-errorbox">
          <label htmlFor="category_en">Category*</label>
          <select
            name="category_en"
            className="category-english"
            aria-label="Category"
            placeholder="Category*"
            onChange={formik.handleChange}
            value={formik.values.category_en}
            onBlur={formik.handleBlur}
          >
            <option value="">Select Category*</option>
            <option value="perfume">Perfume</option>
            <option value="oil">Premium oil</option>
            <option value="Faraash">Faraash</option>
            <option value="Hair Mist">Hair Mist</option>
            <option value="Body Mist">Body Mist</option>
            <option value="Bakhoor">Bakhoor</option>
          </select>
          {formik.touched.category_en && formik.errors.category_en && (
            <div className="error-msg">{formik.errors.category_en}</div>
          )}
        </div>
        <div className="division">
          <div className="grouping-with-errorbox">
            <label htmlFor="qty">Quantity*</label>
            <input
              name="qty"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Quantity*"
              value={formik.values.qty}
              className="quantity"
              type="number"
            />
            {formik.touched.qty && formik.errors.qty && (
              <div className="error-msg">{formik.errors.qty}</div>
            )}
          </div>
          <div className="grouping-with-errorbox">
            <label htmlFor="unit">Unit*</label>
            <select
              name="unit"
              className="unit"
              aria-label="Unit"
              onChange={formik.handleChange}
              placeholder="Unit*"
              value={formik.values.unit}
              onBlur={formik.handleBlur}
            >
              <option value="">Select Unit*</option>
              <option value="ml">ml</option>
              <option value="gm">g</option>
            </select>
            {formik.touched.unit && formik.errors.unit && (
              <div className="error-msg">{formik.errors.unit}</div>
            )}
          </div>
        </div>
        <div className="division">
          <div className="grouping-with-errorbox">
            <label htmlFor="price">Price*</label>
            <input
              name="price"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.price}
              className="price"
              placeholder="Discounted price*"
              type="number"
            />
            {formik.touched.price && formik.errors.price && (
              <div className="error-msg">{formik.errors.price}</div>
            )}
          </div>
          <div className="grouping-with-errorbox">
            <label htmlFor="listed_in">Listed in (optional)</label>
            <select
              name="listed_in"
              className="listed-in"
              aria-label="Listed in"
              onChange={formik.handleChange}
              value={formik.values.listed_in}
              onBlur={formik.handleBlur}
              placeholder="Listed in*"
            >
              <option value="">Select Listed in (optional)</option>
              <option value="new collections">New Collections</option>
              <option value="special collections">Special Collections</option>
              <option value="collections">Collections</option>
            </select>
            {formik.touched.listed_in && formik.errors.listed_in && (
              <div className="error-msg">{formik.errors.listed_in}</div>
            )}
          </div>
        </div>
        <div className="division">
          <div className="grouping-with-errorbox">
            <TagsInput
              name="topNote"
              onChange={(newTags) => formik.setFieldValue("topNote", newTags)}
              onBlur={formik.handleBlur}
              value={formik.values.topNote}
              placeHolder="Top Note"
            />
            <em>Press enter to add new note</em>
            {formik.touched.topNote && formik.errors.topNote && (
              <div className="error-msg">{formik.errors.topNote}</div>
            )}
          </div>
          <div className="grouping-with-errorbox">
            <TagsInput
              name="heartNote"
              onChange={(newTags) => formik.setFieldValue("heartNote", newTags)}
              onBlur={formik.handleBlur}
              value={formik.values.heartNote}
              placeHolder="Heart Note"
            />
            <em>Press enter to add new note</em>
            {formik.touched.heartNote && formik.errors.heartNote && (
              <div className="error-msg">{formik.errors.heartNote}</div>
            )}
          </div>
          <div className="grouping-with-errorbox">
            <TagsInput
              name="baseNote"
              onChange={(newTags) => formik.setFieldValue("baseNote", newTags)}
              onBlur={formik.handleBlur}
              value={formik.values.baseNote}
              placeHolder="Base Note"
            />
            <em>Press enter to add new note</em>

            {formik.touched.baseNote && formik.errors.baseNote && (
              <div className="error-msg">{formik.errors.baseNote}</div>
            )}
          </div>
        </div>

        <label htmlFor="image-thumbnails">Images</label>
        <div className="image-thumbnails">
          {imageThumbs?.map((image, index) => (
            <div
              onDragOver={(e) => handleDragOver(e, index)}
              onDrop={(e) => handleDrop(e, index)}
              className="thumbnail"
              key={index}
            >
              {image.type === "path" ? (
                <img
                  draggable
                  onDragStart={(e) => handleDragStart(e, index)}
                  alt={`Thumbnail ${index}`}
                  style={{ zIndex: 1000 }}
                  src={`${Server}${image?.path}`}
                />
              ) : image.type === "image" ? (
                <div style={{ position: "relative", display: "inline-block" }}>
                  <img
                    draggable
                    onDragStart={(e) => handleDragStart(e, index)}
                    src={URL.createObjectURL(image.image)}
                    alt={`Thumbnail ${index}`}
                    style={{ zIndex: 1000 }}
                  />
                  <span
                    style={{
                      position: "absolute",
                      bottom: "5px",
                      right: "5px",
                      backgroundColor: "rgba(0, 0, 0, 0.6)",
                      color: "#fff",
                      padding: "2px 5px",

                      fontSize: "12px",
                    }}
                  >
                    new
                  </span>
                </div>
              ) : null}

              <TiDelete
                onClick={() => deleteImage(image, index)}
                className="remove-button"
                size={"1.5vw"}
                color={"red"}
                cursor={"pointer"}
              />
            </div>
          ))}
        </div>

        <div className="grouping-with-errorbox">
          <label htmlFor="imageFiles">Add Images</label>
          <input
            name="imageFiles"
            placeholder="Images*"
            onChange={handleImageChange}
            onBlur={formik.handleBlur}
            className="images"
            type="file"
            ref={fileInputRef}
            multiple
            accept="image/*"
            value={""}
          />
          {formik.touched.imageFiles && formik.errors.imageFiles && (
            <div className="error-msg">{formik.errors.imageFiles}</div>
          )}
        </div>

        <button
          className="submit-button"
          disabled={formik.isSubmitting}
          type="submit"
        >
          {formik.isSubmitting ? "Processing..." : "Submit"}
        </button>
      </div>
    </form>
    </>
  );
};

export default EditProduct;
