import React, { useEffect } from "react";
import '../Styles/TermsAndConditions/TermsAndConditions.css';

const TermsAndConditions = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  },[])
  return (
    <div className="terms-and-conditions">
      <h4 className="terms-and-conditions_title">Terms and Conditions</h4>
      <p className="terms-and-conditions_text">
        Access to and use of Alambraperfumes.com and the products and services
        available through the website are subject to the following terms,
        conditions, and notices (“Terms of Service”). By browsing through these
        Terms of Service and using the services provided by our website
        (www.alambraperfumes.com) you agree to all terms of Service along with
        the Privacy Policy on our website, which may be updated by us from time
        to time. Please check this page regularly to take notice of any changes
        we may have made to the Terms of Service.
      </p>
      <p className="terms-and-conditions_text">
        We reserve the right to review and withdraw or amend the services
        without notice. We will not be liable if for any reason this Website is
        unavailable at any time or for any period. From time to time, we may
        restrict access to some parts or this entire Website.
      </p>
      <h5 className="terms-and-conditions_subtitle">Privacy</h5>
      <p className="terms-and-conditions_text">
        Our Privacy Policy incorporated by reference in these Terms of Service
        sets out how we will use the personal information you provide to us. By
        using this Website, you agree to be bound by the Privacy Policy and
        warrant that all data provided by you is accurate and up to date.
      </p>
      <h5 className="terms-and-conditions_subtitle">
        The exactness of the Product
      </h5>
      <p className="terms-and-conditions_text">
        The images of the items on the website are for illustrative purposes
        only. Although we have made every effort to display the colors
        accurately, we cannot guarantee that your computer’s display of the
        colors accurately reflects the color of the items. Your items may vary
        slightly from those images.
      </p>
      <p className="terms-and-conditions_text">
        {" "}
        We take all reasonable care to ensure that all details, descriptions,
        and prices of items are as accurate as possible.
      </p>
      <h5 className="terms-and-conditions_subtitle">Pricing</h5>
      <p className="terms-and-conditions_text">
        We ensure that all details of prices appearing on the website are
        accurate, however, errors may occur. If we discover an error in the
        price of any goods which you have ordered, we will inform you of this as
        soon as possible. If we are unable to contact you we will treat the
        order as canceled. If you cancel and you have already paid for the
        goods, you will receive a full refund.
      </p>
      <p className="terms-and-conditions_text">
        Additionally, prices for items may change from time to time without
        notice. However, these changes will not affect orders that have already
        been dispatched. The price of an item includes VAT (or similar sales
        tax) at the prevailing rate for which we are responsible as a seller.
        Please note that the prices listed on the website are only applicable
        for items purchased on the website and not through any other source.
      </p>
      <h5 className="terms-and-conditions_subtitle">Payment</h5>
      <p className="terms-and-conditions_text">
        Upon receiving your order we carry out a standard pre-authorization
        check on your payment card to ensure there are sufficient funds to
        fulfill the transaction. Goods will not be dispatched until this
        pre-authorization check has been completed. Your card will be debited
        once the order has been accepted.
      </p>
      <p className="terms-and-conditions_text">
        Payment options are available-
      </p>
      <ul>
        <li>Debit card/ Credit Card</li>
        <li>Cash on Delivery</li>
        <li>Apple Pay (might not be available in some cases)</li>
      </ul>
      <h5 className="terms-and-conditions_subtitle">Violation & Termination</h5>
      <p className="terms-and-conditions_text">
        You agree that the Company may, in its sole discretion and without prior
        notice, terminate your access to the website and block your future
        access if we determine that you have violated these Terms of Service or
        any other policies. If you or the Company terminates your use of any
        service, you shall still be liable to pay for any service that you have
        already ordered.
      </p>
      <p className="terms-and-conditions_text">
        If you have any questions, comments or requests regarding our Terms of
        Service or the website please contact us at info@alambraperfumes.com
      </p>
      <h5 className="terms-and-conditions_subtitle">Order Status</h5>
      <p className="terms-and-conditions_text">
        You can check the status of the order by simply going to “My Orders” and
        refer the status column for the status.
      </p>
      <h5 className="terms-and-conditions_subtitle">Service & Complaints:</h5>
      <p className="terms-and-conditions_text">
        The satisfaction of our members is close to our hearts. Therefore we
        endeavor to deal with your concerns as quickly as possible and always
        provide you with our feedback after receiving your input. For service
        inquiries, please contact our customer service department: Email:
        info@alambraperfumes.com
      </p>
      <h5 className="terms-and-conditions_subtitle">
        Incorrect Order Or Product Damaged When Received:
      </h5>
      <p className="terms-and-conditions_text">
        We take utmost care while packing your order to ensure that the right
        product reaches you in the safest way. However, in case of receiving the
        order, if the product is incorrect or is damaged in transit, the same
        would be replaced. The process is hassle-free.
      </p>
      <p className="terms-and-conditions_text">
        In such cases, all you would have to do is that intimate us on the same
        day of receiving the order by informing the delivery agent or writing to
        us at info@alambraperfumes.com or calling us on the contact number
        mentioned on the Contact Us section and your request would be taken care
        of.
      </p>
      <p className="terms-and-conditions_text">
        In case you fail to intimate on the same day of receiving an incorrect
        or damaged product, the product will be considered sold and would not be
        replaced or refunded.
      </p>
      <h5 className="terms-and-conditions_subtitle">Returns</h5>
      <p className="terms-and-conditions_text">
        No item purchased online can be returned at the store in any
        circumstances.
      </p>
      <p className="terms-and-conditions_text">
        Item can be returned only on the same day of delivery with the delivery
        agent upon cases like damaged, wrong product etc.
      </p>
      <p className="terms-and-conditions_text">
        The returned item will be replaced with the actual product in your order
        within a maximum of 7 days. No exchange with any other product is
        allowed.{" "}
      </p>
      <h5 className="terms-and-conditions_subtitle">
        Disclaimer of Guarantee:
      </h5>
      <p className="terms-and-conditions_text">
        We do not guarantee, represent or warrant that your use of our service
        will be uninterrupted, timely, secure or error-free.
      </p>
      <p className="terms-and-conditions_text">
        You agree that from time to time we may remove the service for
        indefinite periods of time or cancel the service at any time, without
        notice to you.
      </p>
      <p className="terms-and-conditions_text">
        You expressly agree that your use of, or inability to use, the service
        is at your sole risk. All products and services delivered to you through
        the service are (except as expressly stated by us) provided ‘as is’ and
        ‘as available’ for your use, without any representation, warranties or
        conditions of any kind, either express or implied, including all implied
        warranties or conditions of merchantability, merchantable quality,
        fitness for a particular purpose, durability and title.
      </p>
      <h5 className="terms-and-conditions_subtitle">Liability:</h5>
      <p className="terms-and-conditions_text">
        You will not hold Al Ambra responsible for other user's Content,
        actions, inactions, or collaborations. We have no control over and do
        not guarantee the quality, safety, or legality of the collaboration
        advertised, the truth or accuracy of users' content, the ability of
        parties to deliver on collaboration, or that the parties will complete a
        transaction.
      </p>
      <p className="terms-and-conditions_text">
        We cannot guarantee continuous or secure access to our services, and the
        operation of the Website may be interfered with by numerous factors
        outside of our control. Accordingly, to the extent legally permitted, we
        exclude all implied warranties, terms, and conditions related to the use
        thereof. We are not liable for any loss of money, goodwill, or
        reputation, or any special, indirect, or consequential damages arising
        out of your use of the Website and services. Some jurisdictions do not
        allow the disclaimer of warranties or exclusion of damages, so such
        disclaimers and exclusions may not apply to you.
      </p>
      <p className="terms-and-conditions_text">
        You must ensure that your access to the Website and Al Ambra Service is
        not illegal or prohibited by laws that apply to you.
      </p>
      <p className="terms-and-conditions_text">
        You must take precautions to ensure that the process you employ for
        accessing Al Ambra's service does not expose you to the risk of viruses,
        malicious computer code, or any other form of interference that may
        damage your computer system. We do not accept responsibility for any
        interference or damage to any computer system that arises in connection
        with your use of the Website or any linked website.
      </p>
      <p className="terms-and-conditions_text">
        We do not accept liability for any losses arising directly or indirectly
        from a failure to provide any service, corruption to or loss of data,
        errors or interruptions, any suspension or discontinuance of service, or
        any transmissions by others in contravention of the registered members'
        obligations as set out in these Terms of Service.
      </p>
      <p className="terms-and-conditions_text">
        You acknowledge that we may not be able to confirm the identity of other
        registered users or prevent them from acting under pretenses or in a
        manner that infringes the rights of any person.
      </p>
    </div>
  );
};

export default TermsAndConditions;
