import React, { useEffect } from 'react';

const TabbyPromo = ({ price, currency = 'AED', installmentsCount = 4, lang = 'en', source = 'product' }) => {
  useEffect(() => {
    // Ensure TabbyPromo is available
    if (window.TabbyPromo) {
      new window.TabbyPromo({
        selector: '#TabbyPromo',
        currency: currency, // AED|SAR|KWD|BHD|QAR only supported, with no spaces or lowercase.
        price: price.toFixed(2), // price or the product. 2 decimals max for AED|SAR|QAR and 3 decimals max for KWD|BHD.
        installmentsCount: installmentsCount, // Optional, for non-standard plans.
        lang: lang, // Optional, language of snippet and popups, if the property is not set, then it is based on the attribute 'lang' of your html tag.
        source: source, // Optional, snippet placement; `product` for product page and `cart` for cart page.
        publicKey: process.env.REACT_APP_TABBY_PUBLIC_TEST_KEY, // required, store Public Key which identifies your account when communicating with tabby.
        merchantCode: process.env.REACT_APP_TABBY_MERCHANT_CODE // required
      });
    }
  }, [price,  currency, installmentsCount, lang, source]);

  return (
    <div id="TabbyPromo"></div>
  );
};

export default TabbyPromo;
