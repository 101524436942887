import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Swal from "sweetalert2";
import "../Styles/AdminForgotPassword/AdminForgotPassword.css";

const AdminForgotPassword = () => {
  const emailValidationSchema = Yup.object().shape({
    email: Yup.string().email("*Invalid email").required("*Email is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },

    validationSchema: emailValidationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);
        const response = await axios.post(
          "/api/admin/auth/forgot-password",
          values
        );
        if (response.data.success) {
          Swal.fire({
            icon: "success",
            title: response.data.message,
          }).then((result) => {
            result.isConfirmed && formik.resetForm();
          });
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: error.response.data.message,
        }).then((result) => {
          result.isConfirmed && formik.resetForm();
        });
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <div className="adminForgotPassword">
      <h4 className="title">Forgot Password ?</h4>
      <form onSubmit={formik.handleSubmit}>
        <div className="form-group">
          <div className="grouping-with-errorbox">
            <label htmlFor="email">Enter your email</label>
            <input
              name="email"
              placeholder="Email*"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              className="email"
              id="email"
              type="email"
            />
            {formik.touched.email && formik.errors.email && (
              <div className="error-msg">{formik.errors.email}</div>
            )}
          </div>
          <button
            className="submit-button"
            disabled={formik.isSubmitting}
            type="submit"
          >
            {formik.isSubmitting ? "Processing..." : "Submit"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AdminForgotPassword;
