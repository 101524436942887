import React, { useEffect } from 'react';

const TabbyCard = ({ price , currency = 'AED', lang = 'en', size = 'narrow', theme = 'black' }) => {
  useEffect(() => {
    // Ensure TabbyCard is available
    const script = document.createElement('script');
    script.src = "https://checkout.tabby.ai/tabby-card.js";
    script.onload = () => {
      new window.TabbyCard({
        selector: '#tabbyCard', // empty div for TabbyCard.
        currency: currency, // required, currency of your product. AED|SAR|KWD|BHD|QAR only supported, with no spaces or lowercase.
        lang: lang, // Optional, language of snippet and popups.
        price: price?.toFixed(2), // required, total price or the cart. 2 decimals max for AED|SAR|QAR and 3 decimals max for KWD|BHD.
        size: size, // required, can be also 'wide', depending on the width.
        theme: theme, // required, can be also 'default'.
        header: true // if a Payment method name present already.
      });
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [price, currency, lang, size, theme]);

  return (
    <div style={{display:'flex',width:'100%',justifyContent:'center',alignItems:'center'}} id="tabbyCard"></div>
  );
};

export default TabbyCard;
