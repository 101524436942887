import { BrowserRouter, Routes, Route } from "react-router-dom";
import LocalizationProvider from "./Components/Localization/LocalizationProvider";
import { Home } from "./Pages/Home";
import { ProductPage } from "./Pages/ProductPage";
import { Cart } from "./Pages/Cart";
import RatingAndReview from "./Pages/RatingAndReview";
import { Orders } from "./Pages/Orders";
import LoginValidation from "./Pages/LoginValidation";
import { Signup } from "./Pages/Signup";
import { Login } from "./Pages/Login";
import PrivateRoutes from "./Utils/PrivateRoutes";
import Footer from "./Components/Footer";
import NotFound from "./Pages/NotFound";
import Shop from "./Pages/Shop";
import Checkout from "./Pages/Checkout";
import { PaymentSuccess } from "./Pages/PaymentSuccess";
import PaymentFailure from "./Pages/PaymentFailure";
import About from "./Pages/About";
import Blog from "./Pages/Blog";
import InvoiceGen from "./Components/Invoice";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import ShippingPolicy from "./Pages/ShippingPolicy";
import CancellationPolicy from "./Pages/CancellationPolicy";
import TermsAndConditions from "./Pages/TermsAndConditions";
import ReturnAndRefund from "./Pages/ReturnAndRefund";
import "./Styles/App/App.css";
import { PaymentCancel } from "./Pages/PaymentCancel";
import { FPProvider } from "./Context/FPContext";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <LocalizationProvider>
          <Routes>
            <Route path="/" exact element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/shop/:category" element={<Shop />} />
            <Route
              path="/checkout/:source/:productId?"
              element={<Checkout />}
            />
            <Route path="/signup" element={<Signup />} />

            <Route
              path="/login"
              element={
                <FPProvider>
                  <Login />
                </FPProvider>
              }
            />

            <Route path="/product/:id" element={<ProductPage />} />
            <Route path="/validation" element={<LoginValidation />} />
            <Route path="/payment/success" element={<PaymentSuccess />} />
            <Route path="/payment/failure" element={<PaymentFailure />} />
            <Route path="/payment/cancel" element={<PaymentCancel />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/shipping-policy" element={<ShippingPolicy />} />
            <Route
              path="/cancellation-policy"
              element={<CancellationPolicy />}
            />
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            />
            <Route path="/return-refund" element={<ReturnAndRefund />} />
            <Route path="*" element={<NotFound />} />
            <Route element={<PrivateRoutes />}>
              <Route
                path="/rating-and-review/:id"
                element={<RatingAndReview />}
              />
              <Route path="/orders" element={<Orders />} />
            </Route>
            <Route path="/invoice" element={<InvoiceGen />} />
          </Routes>
          <Footer />
        </LocalizationProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
