import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "../Styles/PaymentFailure/PaymentFailure.css";

const PaymentFailure = () => {
  const location = useLocation();
  const [statusMessage, setStatusMessage] = useState();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const source = queryParams.get("source");
    if (source && source === "tabby") {
      setStatusMessage(
        "Sorry, Tabby is unable to approve this purchase. Please use an alternative payment method for your order"
      );
    }
  }, [location]);
  return (
    <div className="paymentFailureContainer">
      <h3>Payment Failure</h3>
      <p>
        {statusMessage
          ? statusMessage
          : "Your order placement is failed. Please try again."}
      </p>
    </div>
  );
};

export default PaymentFailure;
