import React, { useEffect } from "react";
import "../Styles/NotFound/NotFound.css";

const AdminNotFound = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="notFoundContainer">
        <h3>Sorry, we couldn't find the page</h3>
      </div>
    </div>
  );
};

export default AdminNotFound;
