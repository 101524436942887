import React, { useEffect, useState } from "react";
import axios from "axios";
import { BeatLoader } from "react-spinners";
import "../Styles/Blog/Blog.css";

const Blog = () => {
  const accessToken = process.env.REACT_APP_INSTAGRAM_ACCESS_TOKEN; // Replace with your access token
  const userId = process.env.REACT_APP_INSTAGRAM_USERID; // Replace with your user ID
  const fields = "id,media_type,media_url,children{media_url},caption";
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let url = `https://graph.instagram.com/${userId}/media?fields=${fields}&access_token=${accessToken}`;
    fetchPosts(url, true);
  }, [accessToken, userId]); // Fetch new posts only when nextPageToken changes


  const [posts, setPosts] = useState([]);
  const [nextPageToken, setNextPageToken] = useState(null);

  const fetchPosts = async (url, isFirstLoad) => {
    try {
      
      const response = await axios.get(url);
      const { data, paging } = response.data;
      const updatePosts = (data) => {
        // Filter the array based on the media_type property
        const updatedData = data.flatMap((element) => {
          if (
            element.media_type === "CAROUSEL_ALBUM" &&
            element.children &&
            element.children.data
          ) {
            // Spread the children.data array if media_type is CAROUSEL_ALBUM
            return element.children.data;
          } else {
            // Otherwise, keep the element as is
            return [element];
          }
        });

        return updatedData;
      };

      isFirstLoad
        ? setPosts(updatePosts(data))
        : setPosts((prevPosts) => [...prevPosts, ...updatePosts(data)]);
      if (paging && paging.next) {
        setNextPageToken(paging.next);
      } else {
        // If no more pages, disable the "Load More" button
        document.getElementById("loadMoreButton").disabled = true;
      }
    } catch (error) {
      console.log("Error fetching instagram data:", error);
    }
  };
 
  // Your React component
  return (
    <>
      {posts.length > 0 ? (
        <>
          <div className="post-container">
            {posts.map((post) => (
              <div className="post" key={post.id}>
                {post.media_type === "VIDEO" ? (
                  <video
                    className="post-image"
                    controls
                    src={post.media_url}
                    alt={post.caption}
                  />
                ) : (
                  <img
                    className="post-image"
                    src={post.media_url}
                    alt={post.caption}
                  />
                )}
              </div>
            ))}
          </div>
          <div className="button-container">
            {nextPageToken && (
              <button
                id="loadMoreButton"
                className="load-more-button"
                onClick={() => fetchPosts(nextPageToken, false)}
              >
                Load More
              </button>
            )}
          </div>
        </>
      ) : (
        <div className="loadingIndicator">
          <h5>Loading</h5>
          <BeatLoader color="#3498db" size={"1.5vw"} />
        </div>
      )}
    </>
  );
};

export default Blog;
