import { useEffect, useState } from "react";
import Rating from "react-rating";
import { ImStarFull, ImStarEmpty } from "react-icons/im";

const StarRating = ({ initialRating, readonly, onChange }) => {
  const [starSize, setStarSize] = useState("1.5vw")
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setStarSize("3vw");
      } else {
        setStarSize("1.5vw");
      }
    };

    // Initial setup
    handleResize();

    // Add a resize event listener
    window.addEventListener("resize", handleResize);

    // Cleanup the listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <Rating
      initialRating={initialRating}
      onChange={onChange}
      emptySymbol=<ImStarEmpty size={starSize} color={"#d4b103"} />
      fullSymbol=<ImStarFull size={starSize} color={"#d4b103"} />
      fractions={1}
      readonly={readonly}
    />
  );
};

export default StarRating;
