// PaymentCancel.js
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "../Styles/PaymentCancel/PaymentCancel.css";

export const PaymentCancel = () => {
  const location = useLocation();
  const [statusMessage, setStatusMessage] = useState();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const source = queryParams.get("source");
    if (source && source === "tabby") {
      setStatusMessage(
        "You aborted the payment. Please retry or choose another payment method."
      );
    }
  }, [location]);

  return (
    <div className="paymentCancelContainer">
      <h3>Payment Cancelled</h3>
      <p>
        {statusMessage
          ? statusMessage
          : "Your payment was cancelled. If this was a mistake, please try again."}
      </p>
    </div>
  );
};
