import React, { useEffect } from "react";
import '../Styles/PrivacyPolicy/PrivacyPolicy.css';

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="privacyPolicy">
      <h4 className="privacyPolicy_title">Privacy Policy</h4>
      <h5 className="privacyPolicy_subtitle">User’s information and privacy:</h5>
      <p className="privacyPolicy_text">
        We are committed to protecting all information that you share with us.
        We have accordingly developed this privacy policy to protect your
        personal information and keep it confidential. We ensure that your
        personal information is secure online.
      </p>
      <p className="privacyPolicy_text">
        We seek to protect your rights of privacy on systems and the site
        (www.alambraperfumes.com). However, we would not be liable for any
        unauthorized or unlawful disclosures of your personal and confidential
        information made by you or any third parties who are not subject to our
        control.
      </p>
      <h5 className="privacyPolicy_subtitle">Cookies:</h5>
      <p className="privacyPolicy_text">
        Like many other transactional websites, we may use “cookies” to improve
        your experience on our website and to save your time. Cookies are little
        tags that we assign to your computer when you first visit our website to
        enable us to recognize you when you return to the website.
      </p>
      <h5 className="privacyPolicy_subtitle">Personal Information provided by You is used only: </h5>
      <ul className="privacyPolicy_text">
        <li>to facilitate Your use of the Website;</li>
        <li>
          to respond to Your inquiries or fulfill Your requests for information
          about the various services;
        </li>
        <li>
          to provide you with information about our products/services and to
          send You information, materials, and offers;
        </li>
        <li>
          to improve the services, content, and advertising on the Website
        </li>
        <li>
          to send you important information regarding the Website, changes to
          Website’s terms, conditions, and policies and/or other administrative
          information;
        </li>
        <li>
          to personalize your experience on the Website by presenting
          advertising, products and offers tailored to You;
        </li>
        <li>
          to help You address Your problems with the Website including
          addressing any technical problems;
        </li>
        <li>
          if You purchase any product from the Website, to complete and fulfill
          Your purchase, for example, to have Your payments processed,
          communicate with You regarding Your purchase and provide You with
          related customer service;
        </li>
        <li>for proper administering of the Website;</li>
        <li>
          to resolve disputes; troubleshoot problems; help promote a safe
          service; collect money;
        </li>
        <li>
          to conduct internal reviews and data analysis for the Website (e.g.,
          to determine the number of visitors to specific pages within the
          Website);
        </li>
      </ul>
      <h5 className="privacyPolicy_subtitle">Security:</h5>
      <p className="privacyPolicy_text">
        The security of your personal information is very important to the
        Company. We have aligned physical, administrative and technical
        safeguards designed to protect your personal information from
        unauthorized access. Also, the Company uses standard security protocols
        and mechanisms to exchange the transmission of sensitive data such as
        credit card details. In the event of any breach of your personal
        information, the Company will notify you of it by email or fax and will
        restore the integrity of the data system.
      </p>
      <h5 className="privacyPolicy_subtitle">Advertising:</h5>
      <p className="privacyPolicy_text">
        The Company uses some third parties to administer a limited set of
        advertisements on our website and portals. During this process, no
        personal information is leaked. However, aggregate profile information,
        such as the user community, may be used in the selection of advertising
        to make sure that it has relevance to the user.
      </p>
      <h5 className="privacyPolicy_subtitle">Use of Services:</h5>
      <p className="privacyPolicy_text">
        Your use of services on alambraperfumes.com and your provision of
        information in connection with these services is purely voluntary. You
        will always have the option of whether to perform the transaction and
        provide this information or not. However, to open an online service
        account or perform a service transaction, you will be required to
        provide some personal information. Whether to perform the transaction is
        up to you.
      </p>
      <p className="privacyPolicy_text">
        If you choose to provide us with personal information, we will use that
        information to help us get you the product or information you have
        requested.
      </p>
      <h5 className="privacyPolicy_subtitle">Important Disclaimer:</h5>
      <p className="privacyPolicy_text">
        Although the Company provides full security and secrecy to your personal
        information, we urge you to be careful with very sensitive information
        and do not share it with any third party claiming to be related to the
        Company on separate emails/phone calls, etc. The Company disclaims any
        and all liabilities towards any such claims that have arisen due to your
        own negligence or willful misconduct and the Company takes no
        responsibility for the same.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
