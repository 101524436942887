import { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoMdClose } from "react-icons/io";
import OTPInput from './OTPInput';
import '../Styles/ForgotPassword/ForgotPassword.css';
import PasswordReset from './PasswordReset'
import { FPContext } from '../Context/FPContext';
import axios from 'axios';

const ForgotPassword = () => {
    const { email, setEmail, OTPSent, OTPVerified, setOTPSent, setOTPSentRes, setDefault, handleClosePopup } = useContext(FPContext);
    const navigate = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState('');
    const handleNavigate = (path) => {
        setDefault();
        setError('');
        handleClosePopup();
        navigate(path);
    }


    const handleOverlayClick = (e) => {
        if (e.target.classList.contains('fp-overlay')) {
            handleClose();
        };
    }

    const handleClose = () => {
        setDefault();
        setError('');
        handleClosePopup();
    }

    const handleSubmit = async () => {
        try {
            setIsSubmitting(true);
            const response = await axios.post(`/api/auth/forgot-password/${email}`);
            if (response?.data.success) {
                setOTPSentRes(response.data.message);
                setOTPSent(true);
                setIsSubmitting(false);
            }
        } catch (error) {
            setIsSubmitting(false);
            setError(error.response?.data.message);
        }

    }

    useEffect(() => {
        // Disable scrolling when the popup is open
        document.body.style.overflow = 'hidden';

        // Re-enable scrolling when the popup is closed
        return () => {
            document.body.style.overflow = '';
        };
    }, []);


    return (

        <div className='fp-overlay' onClick={handleOverlayClick}>
            <section>
                <div className='fp-header'>
                    <h5>Forgot Password ?</h5>
                    <IoMdClose className='fp-close' onClick={handleClose} />
                </div>
                {OTPSent && OTPVerified ? <PasswordReset /> : OTPSent && !OTPVerified ? <OTPInput /> : <div className='fp-body'>
                    <p className='fp-text'>Enter your email address and we will send you an OTP to reset your password.</p>
                    <div className='fp-form'>
                        <input className='fp-input' value={email} onChange={(e) => setEmail(e.target.value)} type="email" placeholder='Email Address' />
                        <button disabled={isSubmitting} onClick={handleSubmit} className='fp-submit'>Submit</button>
                    </div>

                    {error && <div className='fp-error'>{error}</div>}
                </div>}
                <div className='fp-footer'>
                    <h5 onClick={() => handleNavigate('/login')}>Login</h5>
                    <h5 onClick={() => handleNavigate('/signup')}>Register</h5>
                </div>

            </section>
        </div>

    )
}

export default ForgotPassword