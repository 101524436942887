import React, { useState, useEffect } from 'react';
import axios from 'axios';
const TopSelling = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState('Today');
  const [topSellersData, setTopSellersData] = useState();
  // eslint-disable-next-line
  const [error, setError] = useState();
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleFilterClick = (newFilter) => {
    setFilter(newFilter);
    setIsOpen(false);
  };


  useEffect(() => {
    const getTopSellersData = () => {
      axios.get(`/api/admin/dashboardData/topSellers/${filter}`, {
        headers: {
          Authorization: `admin-access-token ${localStorage.getItem("alambraAdminAccessToken")}`
        }
      })
        .then((res) => {
          setTopSellersData(res.data.topSellers);
        })
        .catch((err) => {

          setError(err.response.data.message);
        })
    };

    getTopSellersData();
  }, [filter]);
  console.log("topSellersData", topSellersData)
  return (
    <div className="col-12">
      <div className="card top-selling overflow-auto">
        <div className="filter">
          <p className="icon" href="#" data-bs-toggle="dropdown" onClick={toggleDropdown}><i className="bi bi-three-dots"></i></p>
          <ul className={`dropdown-menu dropdown-menu-end dropdown-menu-arrow ${isOpen ? 'show' : ''}`}>
            <li className="dropdown-header text-start">
              <h6>Filter</h6>
            </li>
            <li><p className="dropdown-item" onClick={() => handleFilterClick('Today')} >Today</p></li>
            <li><p className="dropdown-item" onClick={() => handleFilterClick('This Month')}>This Month</p></li>
            <li><p className="dropdown-item" onClick={() => handleFilterClick('This Year')}>This Year</p></li>
          </ul>
        </div>
        <div className="card-body pb-0">
          <h5 className="card-title">Top Selling <span>| {filter}</span></h5>
          <table className="table table-borderless">
            <thead>
              <tr>
                <th scope="col">Preview</th>
                <th scope="col">Product</th>
                <th scope="col">Price</th>
                <th scope="col">Sold</th>
                <th scope="col">Revenue</th>
              </tr>
            </thead>
            <tbody>
              {topSellersData?.length > 0 ? topSellersData.map((item, index) => (
                <tr key={index}>
                  <th scope="row"><p href="#"><img src={`${process.env.REACT_APP_SERVER}${item.previewImage}`} alt="" /></p></th>
                  <td><p href="#" className="text-primary fw-bold">{item.name}</p></td>
                  <td>AED {item.price}</td>
                  <td className="fw-bold">{item.totalUnitsSold}</td>
                  <td>AED {item.totalRevenue}</td>
                </tr>
              )) : (
                <tr>
                  <td colSpan="5">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "calc(4 * 48px)", // Assuming each row is 48px in height
                        width: "100%",
                        textAlign: "center",
                        fontWeight: "bold",
                      }}
                    >
                      No data found
                    </div>
                  </td>
                </tr>
              )
              }

            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TopSelling;
