import * as Yup from "yup";

const AdminProductsValidationSchema = Yup.object().shape({
    name_en: Yup.string().required("* Name is required"),
    name_ar: Yup.string().required("* Name in Arabic is required"),
    description_en: Yup.string(),
    description_ar: Yup.string(),
    category_en: Yup.string().required("* Category is required"),
    qty: Yup.number().required("* Quantity is required"),
    unit: Yup.string().required("* Unit is required"),
    price: Yup.number().required("* Discounted Price is required"),
    ori_price: Yup.number("* must be a number").notRequired(),
    topNote : Yup.array().of(Yup.string()),
    heartNote : Yup.array().of(Yup.string()),
    baseNote : Yup.array().of(Yup.string()),
    imageFiles: Yup.array().of(
      Yup.mixed().test('fileType', '* Invalid file type, only images are allowed', (value) => {
        if (!value) return true; // Allow empty values (no file uploaded)
    
        const originalFile = value instanceof File ? value : value.image;
        const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
        return allowedTypes.includes(originalFile.type);
      }).test('fileSize', '* File size too large', (value) => {
        if (!value) return true; // Allow empty values (no file uploaded)
    
        const originalFile = value instanceof File ? value : value.image;
        const maxSize = 20 * 1024 * 1024;
        return originalFile.size <= maxSize;
      })
    ),
    
    listed_in: Yup.string(),
});

export default AdminProductsValidationSchema