import * as Yup from "yup";

const phoneSchema = Yup.string().matches(
  /^[1-9]\d{8}$/,
  'Invalid phone number'
);

const delAddressValidationSchema = Yup.object().shape({
    name: Yup.string().required("Full Name is required"),
    addressLine1: Yup.string().required("Address is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("Emirates is required"),
    country: Yup.string().required("Country is required"),
    phone: phoneSchema.required('Phone number is required'),
});

export default delAddressValidationSchema;