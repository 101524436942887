import React from "react";
import {Link} from 'react-router-dom';
import { Formik, Field, ErrorMessage } from "formik";
import subscriberMailValidationSchema from "../Schemas/subscriberMailValidationSchema";
import { useIntl } from "react-intl";
import axios from "axios";
import { toast } from "react-toastify";
import "../Styles/Footer/Footer.css";

const Footer = () => {
  const intl = useIntl();
  const initialmail = {
    email: "",
  };

  const handleSubmit = (values) => {
    axios
      .post(`/api/subscriberMail/add-mail`, values)
      .then((res) => {
        if (res.data.success) {
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  return (
    <>
      <div className="subsribeSection">
        <p className="subscribeTitle">
          {intl.formatMessage({ id: "subscribe.title" })}
        </p>
        <span className="subscribeText">
          {intl.formatMessage({ id: "subscribe.text" })}
        </span>
        <Formik
          initialValues={initialmail}
          onSubmit={handleSubmit}
          validationSchema={subscriberMailValidationSchema}
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div className="inputSection">
                <div className="inputContainer">
                <Field
                  placeholder={intl.formatMessage({
                    id: "subscribe.input.placeholder",
                  })}
                  as="input"
                  type="email"
                  name="email"
                />
                <ErrorMessage
                 name="email" 
                component="div" 
                className="error" />
                </div>
                <button type="submit"
                 className="subscribeButton">
                {intl.formatMessage({ id: "subscribe.buttonText" })}
                </button>
              </div>
            </form>
          )}
        </ Formik>
      </div>
      <div className="aboutSection">
        <div className="contactSection">
          <div className="contactData">
            <div className="contactContent">
              <span>Alambra Perfumes, Dubai, United Arab Emirates,</span>
              <span>{intl.formatMessage({ id: "contact.content2" })}</span>
              <span>{intl.formatMessage({ id: "contact.content3" })}</span>
              <span>{intl.formatMessage({ id: "contact.content1" })}</span>
            </div>
          </div>
        </div>
        <div className="policySection">
          <Link to="/terms-and-conditions">Terms & Conditions</Link>
          <Link to="/privacy-policy">Privacy Policy</Link>
          <Link to="/shipping-policy">Refund Policy</Link>
          <Link to="/cancellation-policy">Cancellation Policy</Link>
          <Link to="return-refund">Return & Refund</Link>
        </div>
      </div>
      <div className="footer">
        <hr />
        <div className="footerText">
          <p>{intl.formatMessage({ id: "footer.text" })}</p>
        </div>
      </div>
    </>
  );
};

export default Footer;
