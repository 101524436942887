import React, { useEffect, useState } from "react";
import useProductStore from "../Store/productStore";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import useUtilsStore from "../Store/utilsStore";
import { useIntl } from "react-intl";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { BeatLoader } from "react-spinners";
import "../Styles/Cart/Cart.css";

export const Cart =  () => {
  const navigate = useNavigate();
  const { userCart, getUserCart, userCartLoading, userCartError } = useProductStore();
  const { isRtl, loggedInUser, getLoggedInUser,loggedInUserLoading,getUserCartCount,getLocalCartCount } = useUtilsStore();
  const intl = useIntl();
  const Server = process.env.REACT_APP_SERVER;
  const [localCartProducts, setLocalCartProducts] = useState([]);
  const subtotal =  loggedInUser
    ? userCart?.product?.reduce(
        (acc, item) => acc + item.productDetails?.price * item?.quantity,
        0
      )
    :  localCartProducts?.reduce((acc, item) => acc + item?.price * item?.qty, 0);
    const netPrice = (Number(subtotal)*Number(0.95)).toFixed(2);
    const totalTax = (Number(subtotal) * Number(0.05)).toFixed(2);

  useEffect(() => {
    getUserCart();
    getLoggedInUser();
  }, [getUserCart, getLoggedInUser]);

  useEffect(() => {
    if (!loggedInUser ) {
      const localCart = JSON.parse(localStorage.getItem("alambraCart"));
      if (localCart) {
        axios
          .post("/api/products/localCartProducts", localCart)
          .then((res) => {
            if (res.data.success) {
              setLocalCartProducts(res.data.localCart);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }, [loggedInUser]);

  useEffect(() => {
    if(!loggedInUser && !loggedInUserLoading){
      const data = localCartProducts.map((item) => {
        return {
          productId: item._id,
          quantity: item.qty,
        };
      });
  
      if (data.length > 0) {
        localStorage.setItem("alambraCart", JSON.stringify(data));
      } else {
        // Handle the case where localCartProducts is empty
        localStorage.removeItem("alambraCart");
      }
    }
    getLocalCartCount();
   
  }, [localCartProducts,loggedInUser,loggedInUserLoading,getLocalCartCount]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleQuantityChange = (value, id) => {
    if (loggedInUser) {
      axios
        .patch(
          `/api/cart/update/${id}`,
          { quantity: value },
          {
            headers: {
              Authorization: `access-token ${localStorage.getItem(
                "alambraAccessToken"
              )}`,
            },
          }
        )
        .then((res) => {
          if (res.data.success) {
            getUserCart();
            getUserCartCount();
          }
        })
        .catch((err) => {
          console.log(err.response.data.message);
        });
    } else {
      setLocalCartProducts((prev) => {
        return prev
          .map((item) => {
            if (item._id === id) {
              const newQty = item.qty + value;
              if (newQty >= 1) {
                return { ...item, qty: newQty };
              } else {
                // If new quantity is less than 1, remove the item
                return null;
              }
            }
            return item;
          })
          .filter((item) => item !== null); // Filter out null items to remove them
      });
     
    }
  };

  const handleRemove = (id, productData, quantity) => {
    Swal.fire({
      title: "Are you sure to remove ?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, remove it!",
    }).then((result) => {
      if (result.isConfirmed) {
        if (loggedInUser) {
          axios
            .delete(`/api/cart/deleteFromCart/${id}`, {
              headers: {
                Authorization: `access-token ${localStorage.getItem(
                  "alambraAccessToken"
                )}`,
              },
            })
            .then((res) => {
              if (res.data.success) {
                getUserCartCount();
                toast.success(
                  `${productData} of quantity ${quantity} is ${res.data.message.toLowerCase()}`
                );
                getUserCart();
              }
            })
            .catch((err) => {
              alert(err.response.data.message);
            });
        } else {
          setLocalCartProducts((prev) => {
            return prev.filter((item) => item._id !== id);
          });
          
        }
      }
    });
  };

  const handleCheckout = () => {
    if(!loggedInUser){
      Swal.fire({
        title: "Please Login to checkout",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Login",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/login",{state:{from:"/cart"}});
        }
      });
    }else{
     navigate("/checkout/cart");
    }
   
  };

  return (
    <div className="cart-container">
      {(loggedInUser && userCart?.userId === loggedInUser?._id) ||
      localCartProducts ? (
        (loggedInUser && userCart?.product?.length > 0) ||
        localCartProducts?.length > 0 ? (
          <>
            <div className="cart-divisions">
              <div className="division1">
                <div className="cart-head">
                  <h5>{intl.formatMessage({ id: "cart.head.product" })}</h5>
                  <h5>{intl.formatMessage({ id: "cart.head.quantity" })}</h5>
                  <h5>{intl.formatMessage({ id: "cart.head.subtotal" })}</h5>
                </div>

                {((loggedInUser && userCart?.product?.length > 0 && userCart?.product ) ||
                  localCartProducts)?.map((product, index) => (
                    <div key={index} className="cart-products-container">
                      <div className="cart-product">
                        <div className="product-details">
                          <div
                            className="image-container"
                            onClick={() =>
                              navigate(`/product/${product?.productId}`)
                            }
                          >
                           
                            <img src={`${Server}${loggedInUser ? product?.productDetails?.rawImages[0]?.path : product?.rawImages[0]?.path}`} alt="" />
                          </div>

                          <h6 className="product-name">
                            {loggedInUser
                              ? isRtl
                                ? product?.productDetails?.name_ar +
                                  " " +
                                  product?.productDetails?.category_ar +
                                  " " +
                                  product?.productDetails?.quantity
                                : product?.productDetails?.name_en +
                                  " " +
                                  product?.productDetails?.category_en +
                                  " " +
                                  product?.productDetails?.quantity
                              : isRtl
                              ? product?.name_ar +
                                " " +
                                product?.category_ar +
                                " " +
                                product?.quantity
                              : product?.name_en +
                                " " +
                                product?.category_en +
                                " " +
                                product?.quantity}
                          </h6>
                          <h6 className="price-in-cart">
                            AED{" "}
                            {loggedInUser
                              ? product?.productDetails?.price
                              : product?.price}
                          </h6>
                          <h6
                            onClick={() =>
                              handleRemove(
                                product?.productId,
                                loggedInUser
                                  ? `${product?.productDetails?.name_en} ${product?.productDetails?.category_en} ${product?.productDetails?.quantity}`
                                  : `${product?.name_en} ${product?.category_en} ${product?.quantity}`,
                                loggedInUser ? product?.quantity : product?.qty
                              )
                            }
                            className="remove"
                          >
                            {intl.formatMessage({ id: "cart.option.remove" })}
                          </h6>
                        </div>

                        <div className="quantity-handler">
                          <button
                            onClick={() =>
                              handleQuantityChange(-1, product.productId)
                            }
                          >
                            -
                          </button>
                          <h5>
                            {loggedInUser ? product.quantity : product.qty}
                          </h5>
                          <button
                            onClick={() =>
                              handleQuantityChange(1, product.productId)
                            }
                          >
                            +
                          </button>
                        </div>

                        <h5>
                          AED{" "}
                          {loggedInUser
                            ? product?.productDetails?.price * product?.quantity
                            : product?.price * product?.qty}
                        </h5>
                      </div>
                    </div>
                  ))}
              </div>
              <hr />
              <div className="division2">
                <div className="cart-total-section">
                  <div className="subtotal">
                    <h5>{intl.formatMessage({ id: "cart.tail.netPrice" })}</h5>
                    <h5>AED {netPrice}</h5>
                  </div>
                  <div className="tax">
                    <h5>{intl.formatMessage({ id: "cart.tail.tax" })}</h5>
                    <h5>AED {totalTax}</h5>
                  </div>
                  <div className="total">
                    <h5>{intl.formatMessage({ id: "cart.tail.total" })}</h5>
                    <h5>AED {subtotal}</h5>
                  </div>
                </div>
                
                <div className="checkout-section">
                  <button onClick={handleCheckout}>
                    {intl.formatMessage({ id: "cart.tail.button" })}
                  </button>
                </div>
                <span className="ship-info">* Shipping charges will be calculated at checkout</span>
              </div>
            </div>
          </>
        ) : (
          <div className="empty-cart">
            <h3>Your cart is empty!</h3>
          </div>
        )
      ) : userCartLoading ? (
        <div
          className="loading"
          style={{
            height: "50vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <BeatLoader color="#3498db" size={"1.5vw"} />
        </div>
      ) : userCartError?.response?.status === 404 ? (
        <div className="empty-cart">
          <h3>Your cart is empty!</h3>
        </div>
      ) : null}
    </div>
  );
};
