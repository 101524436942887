import React, { useEffect } from "react";
import { Formik, Field, ErrorMessage } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";
import axios from "axios";
import signupValidationSchema from "../Schemas/signupValidationSchema";
import { AdvancedImage, lazyload, placeholder } from "@cloudinary/react";
import { cloudinary } from "../Utils/CloudinarySetup";
import "../Styles/Signup/Signup.css";

export const Signup = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const intl = useIntl();
  const navigate = useNavigate();
  const initialValues = {
    username: "",
    email: "",
    password: "",
  };

  const handleGoogleLogin = () => {
    window.location.href = process.env.REACT_APP_GOOGLE_LOGIN_URL;
  };

  const handleSubmit = (values) => {
    axios
      .post(`/api/users/signup`, values)
      .then((res) => {
        if (res.data.success) {
          localStorage.setItem(
            "alambraAccessToken",
            res.headers.get("access-token")
          );
          localStorage.setItem(
            "alambraRefreshToken",
            res.headers.get("refresh-token")
          );
          toast.success(res.data.message);
          navigate("/");
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  return (
    <div className="signup">
      <div className="mobile-screen">
        <div className="background-container">
          <div className="banner-section">
            <AdvancedImage
              cldImg={cloudinary
                .image("alambra stock images/rdbqy0fyqgfg0t16qo0g")
                .format("auto")
                .quality("auto")}
              plugins={[lazyload(), placeholder({ mode: "blur" })]}
            />
          </div>

          <div className="signup-section">
            <div className="classic-signup">
              <div className="signup-header">
                <h5>{intl.formatMessage({ id: "signup.title" })}</h5>
                <Link to="/login">
                  {intl.formatMessage({ id: "signup.login" })}
                </Link>
              </div>
              <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={signupValidationSchema}
              >
                {({ handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="signup-form-container">
                      <div className="username-input-area">
                        <Field
                          placeholder={intl.formatMessage({
                            id: "signup.placeholder.username",
                          })}
                          type="text"
                          name="username"
                          className="input1"
                        />
                        <ErrorMessage
                          name="username"
                          component="div"
                          className="error error1 error-username"
                        />
                      </div>
                      <div className="email-input-area">
                        <Field
                          placeholder={intl.formatMessage({
                            id: "signup.placeholder.email",
                          })}
                          type="email"
                          name="email"
                          className="input2"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="error error2 error-email"
                        />
                      </div>
                      <div className="password-input-area">
                        <Field
                          placeholder={intl.formatMessage({
                            id: "signup.placeholder.password",
                          })}
                          type="password"
                          name="password"
                          className="input3"
                        />
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="error error3 error-password"
                        />
                      </div>

                      <button type="submit">
                        {intl.formatMessage({ id: "signup.button" })}
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
            <hr />
            <div className="social-login-section">
              <p>{intl.formatMessage({ id: "signup.option" })}</p>
              <div className="social-login">
                <img
                  onClick={handleGoogleLogin}
                  src="../../assets/images/social_media/google.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
