import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "../Styles/CancellationPolicy/CancellationPolicy.css";

const CancellationPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  },[])
  return (
    <div className="CancellationPolicy">
      <h4 className="CancellationPolicy_title">Cancellation Policy</h4>
      <p className="CancellationPolicy_text">
        Al Ambra reserve right to cancel any order without any explanation. We
        ensure you, communication of cancelation of an order or refund will be
        done in a reasonable time.
      </p>
      <p className="CancellationPolicy_text">
        1) You can cancel the order from the{" "}
        <Link to="/orders" target="_blank" rel="noopener noreferrer">
          My Orders
        </Link>{" "}
        Section in Account before the order is out for delivery.
      </p>
      <p className="CancellationPolicy_text">
        2) If the order is already shipped, we won't be able to cancel it.
        However, you can refuse to accept the shipment and the amount will be
        refunded to your account.
      </p>
      <p className="CancellationPolicy_text">
        3) The full amount will be refunded to your account, including your
        shipping charges if any.
      </p>
      <p className="CancellationPolicy_text">
        *If you don't receive your order in 7-10 Days, please email us at
        info@alambraperfumes.com with all the details or you may contact us at
        +971 56 669 5370 or (04) 885 1505 between 10.00 - 06.00 on all working
        days.
      </p>
    </div>
  );
};

export default CancellationPolicy;
