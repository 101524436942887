import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
// import { useIntl } from "react-intl";
import useUtilsStore from "../Store/utilsStore";
import useProductStore from "../Store/productStore";
import "../Styles/Shop/Shop.css";

const Shop = () => {
  const { category } = useParams();
  const { products, getProducts } = useProductStore();
  const { isRtl } = useUtilsStore();
  const navigate = useNavigate();
  const Server = process.env.REACT_APP_SERVER;
  // const intl = useIntl();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!products) {
      getProducts();
    }
  }, [getProducts, products]);

  useEffect(() => {
    const categoryData = [
      "Perfumes",
      "Body Mist",
      "Hair Mist",
      "Bakhoor",
      "Premium Oils",
      "Faraash",
    ];
    if (!categoryData.includes(category)) {
      navigate("/not-found");
    }
  }, [category, navigate]);

  const filteredProducts = products?.filter((product) => {
    return (
      product.category_en.toLowerCase() ===
      (category.toLowerCase() === "premium oils"
        ? "oil"
        : category.toLowerCase() === "perfumes"
        ? "perfume"
        : category.toLowerCase())
    );
  });

  const handleNavigate = (id) => {
    navigate(`/product/${id}`);
  };

  const renderShopContainers = () => {
    if (category.toLowerCase() === "perfumes") {
      return (
        <>
          <div className="shop-container">
            <div className="logo2Container">
              <img
                className="logo2"
                src="../../assets/images/LOGO3.png"
                alt="Collection"
              />
            </div>
            <span className="shopTitle">Perfumes 50ml</span>
          </div>

          <div className="shopCardContainer">
            {renderFilteredProducts("50ml")}
          </div>

          <div className="shop-container">
            <div className="logo2Container">
              <img
                className="logo2"
                src="../../assets/images/LOGO3.png"
                alt="Collection"
              />
            </div>
            <span className="shopTitle">Perfumes 100ml</span>
          </div>

          <div className="shopCardContainer">
            {renderFilteredProducts("100ml")}
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="shop-container">
            <div className="logo2Container">
              <img
                className="logo2"
                src="../../assets/images/LOGO3.png"
                alt="Collection"
              />
            </div>
            <span className="shopTitle">{category}</span>
          </div>

          <div className="shopCardContainer">
            {filteredProducts?.map((item, index) => (
              <div
                key={index}
                className={`shopCard ${item?.out_of_stock ? "outOfStock" : ""}`}
                onClick={() => {
                  if (!item?.out_of_stock) {
                    handleNavigate(item._id);
                  }
                }}
              >
                {item?.out_of_stock && (
                  <div className="outOfStockOverlay">Out of Stock</div>
                )}

                <div className="shopImageCard">
                  <img
                    src={`${Server}${item?.rawImages[0]?.path}`}
                    alt=""
                    className="shopImage"
                  />
                </div>
                <span className="shopItemTitle">
                  {isRtl ? item.name_ar : item.name_en.toUpperCase()}
                </span>
                <div className="shopItemPriceContainer">
                  {/* <span style={{textDecoration: "line-through",color:"grey"}} className="shopItemOriPrice">AED {item?.ori_price ?? item.price+20}</span> */}
                  <span className="shopItemPrice">AED {item.price}</span>
                </div>
              </div>
            ))}
          </div>
        </>
      );
    }
  };

  const renderFilteredProducts = (quantity) => {
    return filteredProducts
      ?.filter((product) => product.quantity === quantity)
      .map((item, index) => (
        <div
          key={index}
          className={`shopCard ${item?.out_of_stock ? "outOfStock" : ""}`}
          onClick={() => {
            if (!item?.out_of_stock) {
              handleNavigate(item._id);
            }
          }}
        >
          {item?.out_of_stock && (
            <div className="outOfStockOverlay">Out of Stock</div>
          )}

          <div className="shopImageCard">
            <img
              src={`${Server}${item?.rawImages[0]?.path}`}
              alt=""
              className="shopImage"
            />
          </div>
          <span className="shopItemTitle">
            {isRtl ? item.name_ar : item.name_en.toUpperCase()}
          </span>
          <div className="shopItemPriceContainer">
            {/* <span style={{textDecoration: "line-through",color:"grey"}} className="shopItemOriPrice">AED {item?.ori_price ?? item.price+20}</span> */}
            <span className="shopItemPrice">AED {item.price}</span>
          </div>
        </div>
      ));
  };

  return <>{renderShopContainers()}</>;
};

export default Shop;
